import React, {useCallback, useMemo, useState} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationChatGpt from "../useTranslationChatGpt.js";
import isUserAuthorized from "../../authentication-no-ui/isUserAuthorized.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import ChatWindow from "../../common/chat/ChatWindow.js";
import useLazyQueryChatGptAsk from "./useLazyQueryChatGptAsk.js";
import useChatConversation from "../../common/chat/useChatConversation.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import SavedPrompts from "./SavedPrompts.js";
//import useLazyQueryChatGptAskById from "./useLazyQueryChatGptAskById.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";
import ProtectedIcon from "@mui/icons-material/VerifiedUser";
import "./ChatGptBlock.scss";

export default function ChatGptBlock() {

  const { t, loading: tLoading } = useTranslationChatGpt();
  useShowLoader(tLoading, "ChatGptBlock");

  const [historyMessages, setHistoryMessages] = useState([]);
  const {messages, addOutboundMessage, addInboundMessage, addErrorMessage, resetConversation, setIsSubmitting, isSubmitting} = useChatConversation(t("chatgpt:chat_welcome_msg"));

  const authUser = useAuthUser();
  const profile = authUser && authUser.profile;
  const organization = profile && profile.organization;
  const isGptEnabled = organization && organization.isGptEnabled;
  const employerId = authUser && authUser.profile && authUser.profile._id;

  const addHumanMessageToHistory = useCallback((prompt, promptId = undefined, promptParams = undefined) =>
      setHistoryMessages(prevMessages => [...prevMessages, {
        type: "humanMessage",
        content: prompt,
        promptId,
        promptParams,
      }]),
    []);

  const addAiMessageToHistory = useCallback(content =>
      setHistoryMessages(prevMessages => [...prevMessages, {
        type: "aiMessage",
        content: content
      }]),
    []);

  const resetHistory = useCallback(() => setHistoryMessages([]), []);

  const {getCurrentLanguageCode} = useLanguages();
  const lang = getCurrentLanguageCode().toUpperCase();

  const { fetch: fetchChatGpt } = useLazyQueryChatGptAsk();
  //const { fetch: fetchChatGptById } = useLazyQueryChatGptAskById();

  // We use tLoading in dependencies because t does not change when loading is completed
  const remoteUserComponent = useMemo(() => !tLoading &&
    <b>{t("chatgpt:chat_remote_user")}:&nbsp;</b>,
    [t, tLoading]);
  const localUserComponent = useMemo(() => !tLoading &&
    <b>{t("chatgpt:chat_local_user")}:&nbsp;</b>,
    [t, tLoading]);

  /*
  const onSendStandardPrompt = useCallback((prompt, promptId, promptParams) => {
    resetConversation();
    resetHistory();
    addOutboundMessage(prompt);
    setIsSubmitting(true);

    return fetchChatGptById({
      employerId,
      promptId,
      lang,
      promptParams,
      historyMessages: [], // There is no guarantee that resetHistory has finished updating historyMessages
    }).then(answer => {
      addInboundMessage(HtmlUtils.nlToBr(answer));
      addHumanMessageToHistory(prompt, promptId, promptParams);
      addAiMessageToHistory(answer);
      return Promise.resolve(answer);
    }).catch(error => {
      addErrorMessage(t("chatgpt:chat_error_msg"));
      return Promise.reject(error)
    }).finally(() => setIsSubmitting(false));
  }, [resetConversation, resetHistory, addOutboundMessage, setIsSubmitting, fetchChatGptById, employerId, lang, addInboundMessage, addHumanMessageToHistory, addAiMessageToHistory, t, addErrorMessage]);
  */

  const onSendSavedPrompt = useCallback((prompt, longPrompt, promptParams) => {
    resetConversation();
    resetHistory();
    addOutboundMessage(prompt, longPrompt);
    setIsSubmitting(true);

    return fetchChatGpt({
      employerId,
      prompt: longPrompt,
      historyMessages: [], // There is no guarantee that resetHistory has finished updating historyMessages
    }).then(answer => {
      addInboundMessage(HtmlUtils.nlToBr(answer));
      addHumanMessageToHistory(longPrompt, undefined, promptParams);
      addAiMessageToHistory(answer);
      return Promise.resolve(answer);
    }).catch(error => {
      addErrorMessage(t("chatgpt:chat_error_msg"));
      return Promise.reject(error)
    }).finally(() => setIsSubmitting(false));
  }, [resetConversation, resetHistory, addOutboundMessage, setIsSubmitting, fetchChatGpt, employerId, addInboundMessage, addHumanMessageToHistory, addAiMessageToHistory, t, addErrorMessage]);

  const onSendPrompt = useCallback((prompt) => {
    addOutboundMessage(prompt);
    setIsSubmitting(true);

    return fetchChatGpt({
      employerId,
      prompt,
      historyMessages
    }).then(answer => {
      addInboundMessage(HtmlUtils.nlToBr(answer));
      addHumanMessageToHistory(prompt);
      addAiMessageToHistory(answer);
      return Promise.resolve(answer);
    }).catch(error => {
      addErrorMessage(t("chatgpt:chat_error_msg"));
      return Promise.reject(error)
    }).finally(() => setIsSubmitting(false));
  }, [addOutboundMessage, setIsSubmitting, fetchChatGpt, employerId, historyMessages, addInboundMessage, addHumanMessageToHistory, addErrorMessage, t, addAiMessageToHistory]);

  if (!isUserAuthorized(authUser, "CHATGPT_VIEW", isGptEnabled))
    return t("chatgpt:page_forbidden");

  return (
    <div className="ChatGptBlock">
      <Row>
        <Col className={"widget-block chat-col"}>
          <Row>
            <Col className={"protected-col"}>
              <span className={"protected-badge"}><ProtectedIcon fontSize={"inherit"}/></span>{t("chatgpt:chat_protected")}
            </Col>
          </Row>
          <Row>
            <Col>
              <ChatWindow
                messages={messages}
                onSubmit={onSendPrompt}
                remoteUserComponent={remoteUserComponent}
                localUserComponent={localUserComponent}
                isSubmitting={isSubmitting}
              />
            </Col>
          </Row>
        </Col>
        <Col className={"empty-col"}/>
        <Col className={"prompt-list"}>
          <Row>
            <Col className={"prompt-title-col"}>
              <h2>{t("chatgpt:chat_suggested_prompts_title")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className={"prompt-list-col"}>
              {/*<StandardPrompts onSelect={onSendStandardPrompt}/>*/}
              <SavedPrompts onSelect={onSendSavedPrompt} organization={organization} lang={lang} profile={profile}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
