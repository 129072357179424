import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryApplicationCountByDate from "./useQueryApplicationCountByDate.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import CountLineChart from "./CountLineChart.js";

export default function ApplicationCountByDateItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationCountItem");

  const { data, loading, errors } = useQueryApplicationCountByDate();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="ApplicationCountItem">
            <PageSubtitle
              colTitle={t(
                "admin:admin_dashboard_application_count_by_date_title"
              )}
            />
            <Row>
              <Col>
                <CountLineChart data={data} />
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
