import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationSearchApprovedDateGroupAndCount";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($jobId: ID!, $filter: ApplicationFilterInput) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(jobId: $jobId, filter: $filter) {
        date
        count
      }
    }
  }
`;

export default function useQueryDateGroupAndCount(jobId, filter) {
  const variables = {
    jobId,
    filter,
  };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
