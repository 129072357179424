import React, {useState} from "react";
import "./ChatMessage.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import StringUtils from "../../../utils/StringUtils.js";
import DotsLoader from "../loading-widgets/DotsLoader.js";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ActionIcon from "../widgets/ActionIcon.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";

export default function ChatMessage({message, remoteUserComponent, localUserComponent}) {

  switch (message.source) {
    case "inbound":
      return <ChatMessageStructure
        className={"inbound"}
        userComponent={remoteUserComponent}
        messageComponent={<>{message.content}</>}
      />;
    case "outbound":
      return <ChatMessageStructure
        className={"outbound"}
        userComponent={localUserComponent}
        messageComponent={
          <>
            {message.content}
            {message.extraInfo && <ChatMessageExtraInfo>{HtmlUtils.nlToBr(message.extraInfo)}</ChatMessageExtraInfo>}
          </>
        }
      />;
    case "wait":
      return <ChatMessageStructure
        className={"inbound wait"}
        userComponent={remoteUserComponent}
        messageComponent={<>{message.content}<DotsLoader/></>}
      />;
    default:
      return null;
  }
};

function ChatMessageStructure({userComponent, messageComponent, className}) {

  return (
    <div className={`ChatMessage ${StringUtils.nullToEmpty(className)}`}>
      <Row className={"main-row"}>
        <Col className={"user-col"}>
          {userComponent}
        </Col>
        <Col className={"message-col"}>
          {messageComponent}
        </Col>
      </Row>
    </div>
  )
}

function ChatMessageExtraInfo({children =  null}) {

  const [show, setShow] = useState(false);

  return (
    <>
      <ActionIcon className={"info-icon"} icon={InfoIcon} onClick={() => setShow((prevState) => !prevState)}/>
      {show && <div className={"extra-info"}>{children}</div>}
    </>
  )
}
