import React from "react";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import "./JobCountItem.scss";
import JobStatusEnum, {getJobStatusLabel} from "../../jobs/JobStatusEnum.js";
import useTranslationJobs from "../../jobs/useTranslationJobs.js";
import JobStatusCountItem from "./JobStatusCountItem.js";

export default function JobCountItem(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  const {t: jobT, loading: jobTLoading } = useTranslationJobs();

  useShowLoader(tLoading || jobTLoading, "JobCountItem");

  return (
    <div className="JobCountItem">
      <PageSubtitle
        colTitle={t("admin:admin_dashboard_job_count_title")}
      />
      {Object.keys(JobStatusEnum).map(status =>
          <JobStatusCountItem key={status} status={status} statusLabel={getJobStatusLabel(jobT, status)}/>
      )}
    </div>
  );
}
