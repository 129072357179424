import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";
import { QUERY as QUERY_STATUS_COUNT } from "../search/useQueryStatusGroupAndCount.js";
import { QUERY as QUERY_APPLICATION_SEARCH } from "../search/useQueryApplicationSearch.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationBatchUpdateStatus";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $ids: [ID!]!,
      $status: ApplicationStatusEnum!
      $effectiveDate: Date
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          ids: $ids,
          status: $status,
          effectiveDate: $effectiveDate
        ) {
          _id
          status
        }
      }
    }
  `;

export default function useMutationApplicationBatchUpdateStatus() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_STATUS_COUNT, QUERY_APPLICATION_SEARCH]
  });
}
