import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useQueryJobCount from "./useQueryJobCount.js";

export default function JobStatusCountItem(props) {
  const { status, statusLabel } = props;

  const {
    data: count,
    loading,
    errors,
  } = useQueryJobCount({ status });

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="JobStatusCountItem">
            <Row>
              <Col>
                <span>{statusLabel}</span>
              </Col>
              <Col>
                <span>{count}</span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
