import React from "react";
import AppRouter from "./AppRouter.js";
import UntranslatedContentError from "../common/error-boundaries/UntranslatedContentError.js";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary.js";

// Main App
export default function App() {
  return (
    <ErrorBoundary component={<UntranslatedContentError />}>
      <AppRouter />
    </ErrorBoundary>
  );
}
