import React from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import JobMaxCommissionTip from "./JobMaxCommissionTip.js";
import JobAverageCommissionTip from "./JobAverageCommissionTip.js";
import JobSimilarJobsCommissionTip from "./JobSimilarJobsCommissionTip.js";
import "./JobSaveCommissionTip.scss"

export default function JobSaveCommissionTip(props) {
  const { similarJobs, jobGlobalMaxCommission, jobGlobalAverageCommission } =
    props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSaveCommissionTip");

  return (
    <>
      <div className="JobSaveCommissionTip">
        <Row>
          <Col>
            <h2>{t("jobs:employers_job_save_tip_title")}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <JobSimilarJobsCommissionTip similarJobs={similarJobs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <JobAverageCommissionTip jobGlobalAverageCommission={jobGlobalAverageCommission} similarJobs={similarJobs} />
            <JobMaxCommissionTip jobGlobalMaxCommission={jobGlobalMaxCommission} similarJobs={similarJobs} />
          </Col>
        </Row>
        <Row>
          <Col>
            {t("jobs:employers_job_save_tip_msg2")}
          </Col>
        </Row>
      </div>
    </>
  );
}
