import React, {useCallback, useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import StringUtils from "../../../utils/StringUtils.js";
import "./ApplicationUpdateForm.scss";
import ActionButton from "../../common/widgets/ActionButton.js";
import ApplicationInlineEditInputWysiwyg from "./ApplicationInlineEditInputWysiwyg.js";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CallIcon from "@mui/icons-material/Call";
import useTranslationCandidates from "../useTranslationCandidates.js";
import Separator from "../../common/widgets/Separator.js";
import Dropdown from "react-bootstrap/Dropdown";
import RecruiterProfile from "../../recruiters/view/RecruiterProfile.js";
import useContentModal from "../../common/modals/useContentModal.js";
import ActionDiv from "../../common/widgets/ActionDiv.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import ResumeDownloadIcon from "../view/ResumeDownloadIcon.js";
import useQueryApplicationById from "../view/useQueryApplicationById.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import { MAX_LENGTH_EMPLOYER_NOTE } from "../../../constants/ui.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import useApplicationUpdateStatus from "./useApplicationUpdateStatus.js";
import useApplicationEffectiveStatusDate from "../view/useApplicationEffectiveStatusDate.js";

export default function ApplicationUpdateForm(props) {
  const { applicationId, onClose, onChangeStatus } = props;

  const {
    data: application,
    loading: applicationLoading,
    errors: applicationErrors,
  } = useQueryApplicationById(applicationId);

  const {ready: applicationUpdateStatusReady, BatchUpdateStatusModal, launchChangeStatus} = useApplicationUpdateStatus(application && application.job);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading || !applicationUpdateStatusReady, "ApplicationUpdateForm");

  const [initialApplicationStatus, setInitialApplicationStatus] = useState(undefined);

  useEffect(() => {
    if (application && !initialApplicationStatus)
      setInitialApplicationStatus(application.status)
  }, [application, initialApplicationStatus]);

  const onLocalClose = useCallback(() => {
    onClose();
    if (onChangeStatus && application && (application.status !== initialApplicationStatus))
      onChangeStatus(application.status);
  }, [onClose, onChangeStatus, application, initialApplicationStatus]);

  const onClickLink = (url) => {
    RouterUtils.openExternalUrl(url);
  };

  // Parse recruiter note only when application object changes
  const recruiterNote = React.useMemo(() => {
    return application
      ? HtmlUtils.htmlToComponents(application.recruiterNote)
      : "";
  }, [application]);

  const filterLabel = {
    STACK: t("candidates:candidates_send_to_stack_label"),
    REJECTED: t("candidates:candidates_send_to_rejected_label"),
    SHORTLIST: t("candidates:candidates_send_to_shortlist_label"),
    HIRED: t("candidates:candidates_send_to_hired_label"),
    HIRING_CANCELLED: t("candidates:candidates_send_to_hiring_cancelled_label")
  };

  const handleChangeStatus = (status) => {
    launchChangeStatus([application._id], status);
  };

  // Modal to show recruiter details form
  const {
    ContentModal: RecruiterProfileModal,
    show: showRecruiterProfileModal,
    hide: hideRecruiterProfileModal,
  } = useContentModal("CandidateApplicationPreview");

  const onClickRecruiter = () => {
    showRecruiterProfileModal(getRecruiterProfile());
  };

  const getRecruiterProfile = () => {
    return (
      <RecruiterProfile
        onClose={hideRecruiterProfileModal}
        application={application}
      />
    );
  };

  const effectiveDate = useApplicationEffectiveStatusDate(application);

  return (
    <>
      {RecruiterProfileModal} {BatchUpdateStatusModal}
      <WaitForData
        loading={applicationLoading}
        errors={applicationErrors}
        onLoaded={() => (
          <div className={"ApplicationUpdateForm"}>
            <Row className={"first-row"}>
              <Col className="candidateInformationContact">
                <Row className={"name-row"}>
                  <Col className="name-col">
                    <h1>{application.candidate.name}</h1>
                  </Col>
                </Row>
                {application.resumeId && (
                  <Row className={"resume-row"}>
                    <Col>
                      <ResumeDownloadIcon
                        resumeId={application.resumeId}
                        showLabel={true}
                      />
                    </Col>
                  </Row>
                )}
                <Row className={"label-row"}>
                  <Col>
                    {t("candidates:candidates_contact_information_label")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row className={"contact-row"}>
                      <Col>
                        <EmailIcon /> {application.candidate.email}
                      </Col>
                    </Row>
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.phone
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <CallIcon /> {application.candidate.phone}
                        </Col>
                      </Row>
                    )}
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.linkedin
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <LinkedInIcon />
                          <ActionLink
                            onClick={() =>
                              onClickLink(application.candidate.linkedin)
                            }
                          >
                            {t("candidates:candidates_linkedin_label")}
                          </ActionLink>
                        </Col>
                      </Row>
                    )}
                    {!StringUtils.isNullOrEmpty(
                      application.candidate.indeed
                    ) && (
                      <Row className={"contact-row"}>
                        <Col className="icon-col">
                          <AccountBoxIcon />
                          <ActionLink
                            onClick={() =>
                              onClickLink(application.candidate.indeed)
                            }
                          >
                            {t("candidates:candidates_indeed_label")}
                          </ActionLink>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Separator />
            <Row>
              <Col className={"status-col"}>
                {onChangeStatus &&
                  <>
                    <Col className="icon-col">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-status">
                          {filterLabel[application.status]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {Object.keys(filterLabel)
                            .filter((item) => item !== application.status)
                            .map((item) => (
                              <Dropdown.Item
                                key={item}
                                onClick={() => handleChangeStatus(item)}
                              >
                                {filterLabel[item]}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </>
                }
                {!onChangeStatus && <div>{filterLabel[application.status]}</div>}
              </Col>
              <Col className={"effective-col"}>
                {effectiveDate && <>{t("candidates:candidates_details_effective_label")} {effectiveDate}</>}
              </Col>
            </Row>
            {application.recruiter.isActiveAccount && (
              <>
                <Row className={"label-row"}>
                  <Col>{t("candidates:candidates_recruiter_name_label")}</Col>
                </Row>
                <Row>
                  <Col>
                    <ActionDiv onClick={() => onClickRecruiter()}>
                      {application.recruiter.firstName}{" "}
                      {application.recruiter.lastName}
                    </ActionDiv>
                  </Col>
                </Row>
              </>
            )}
            {!application.recruiter.isActiveAccount && (
              <Row>
                <Col className={"label-row"}>
                  {t("candidates:candidates_recruiter_deleted_account_label")}
                </Col>
              </Row>
            )}
            {!StringUtils.isNullOrEmpty(recruiterNote) && (
              <>
                <Row className={"label-row"}>
                  <Col>{t("candidates:candidates_recruiter_note_label")}</Col>
                </Row>
                <Row>
                  <Col>{recruiterNote}</Col>
                </Row>
              </>
            )}
            <Separator />
            <Row className={"label-row"}>
              <Col> {t("candidates:candidates_note_label")}</Col>
            </Row>
            <Row>
              <Col className="note-col">
                <ApplicationInlineEditInputWysiwyg
                  className={"note-input"}
                  application={application}
                  placeholder={t(
                    "candidates:candidates_candidate_note_placeholder"
                  )}
                  property={"employerNote"}
                  maxLength={MAX_LENGTH_EMPLOYER_NOTE}
                />
              </Col>
            </Row>
            <Row>
              <Col className="col-actionButton">
                <ActionButton onClick={onLocalClose}>
                  {t("candidates:candidates_close_action")}
                </ActionButton>
              </Col>
            </Row>
          </div>
        )}
      />
    </>
  );
}
