import React from "react";
import OrganizationInlineEditInput from "./OrganizationInlineEditInput.js";
import InlineEditValidatedInputWysiwyg from "../../common/widgets/InlineEditValidatedInputWysiwyg.js";

/**
 * Generic component for all organization editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrganizationInlineEditInputWysiwyg(props) {
  return <OrganizationInlineEditInput InlineEditInputComponent={InlineEditValidatedInputWysiwyg} {...props}/>
};
