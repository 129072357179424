import {gql} from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";

const QUERY_SCOPE = "employer";
const QUERY_NAME = "employerDelete";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} ($id: ID!) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (id: $id) {
          _id
        }
      }
    }
  `;

export default function useMutationProfileDelete() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
