import React from "react";
import { InputGroup } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import TypeUtils from "../../../utils/TypeUtils.js";
import { Typeahead } from "react-bootstrap-typeahead";
import "./SearchBox.scss";

export default function SearchBox(props) {

  const { className, useQueryOptions, onSelectOption, errorMsg, emptyMsg, placeholder, labelKey, setSearchValue } = props;

  const { options: rawOptions, loading: optionsLoading, errors: optionsErrors } = useQueryOptions();

  const onChangeSelection = (options) => {
    if (!TypeUtils.arrayIsEmpty(options))
      onSelectOption(options[0]);
  }

  // Don't prevent the search box from rendering even if we are still loading the options or if an error occurs,
  // simply display no options until they come in
  const options = optionsLoading || optionsErrors ? [] : rawOptions;

  // Use the typeahead empty label prop to display an error message
  const emptyLabel = optionsErrors ? errorMsg : emptyMsg;
  const setValue = input => {
    if (setSearchValue)
      setSearchValue(input);
  }

  return (
    <div className={["SearchBox", className].join(" ")}>
      <InputGroup>
        <Typeahead
          id="searchInput"
          onChange={selected => onChangeSelection(selected)}
          placeholder={placeholder}
          labelKey={labelKey}
          options={options}
          emptyLabel={emptyLabel}
          highlightOnlyResult
          paginate={false}
          className={"search-input"}
          onInputChange={setValue}
        />
        <InputGroup.Text className={"search-icon"}><SearchIcon /></InputGroup.Text>
      </InputGroup>
    </div>
  );
};
