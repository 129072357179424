import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import ActionButton from "../common/widgets/ActionButton.js";
import StringUtils from "../../utils/StringUtils.js";
import { useFormFields } from "../infra-no-ui/react-helpers/useFormFields.js";
import useUpdatePassword from "../authentication-no-ui/useUpdatePassword.js";
import useErrorModal from "../common/modals/useErrorModal.js";
import useServerErrorFormatter from "../common/modals/useServerErrorFormatter.js";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";

export default function UpdatePasswodForm(props) {
  const { onAbort, onSuccess } = props;

  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading);

  const [fields, handleFieldChange] = useFormFields({
    newPassword: "",
    confirmedNewPassword: "",
  });

  const { updatePassword, loading, error } = useUpdatePassword();
  const errorMsg = useServerErrorFormatter(error);

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("authentication-ui:employers_profile_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (event) => {
    event.preventDefault();
    // TODO: onSuccess is never called, a successful password signs out the user and the Sign-In form appears
    updatePassword(fields.newPassword)
      .then(() => onSuccess())
      .catch(() => showErrorModal())
  };

  const canSubmit = () => {
    return (
      fields.newPassword !== "" &&
      fields.newPassword === fields.confirmedNewPassword
    );
  };

  return (
    <>
      {ErrorModal}
      <div className="UpdatePasswodForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <h1>{t("authentication-ui:employers_update_password_msg")}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup controlId="newPassword">
                <FormControl
                  className="FormControl"
                  autoFocus
                  type="password"
                  value={StringUtils.nullToEmpty(fields.newPassword)}
                  onChange={handleFieldChange}
                  placeholder={t(
                    "authentication-ui:employers_update_password_placeholder"
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup controlId="confirmedNewPassword">
                <FormControl
                  name="confirmedNewPassword"
                  type="password"
                  value={StringUtils.nullToEmpty(fields.confirmedNewPassword)}
                  onChange={handleFieldChange}
                  placeholder={t("authentication-ui:employers_confirm_update_password_msg")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButton onClick={onAbort}>
                {t("authentication-ui:employers_cancel_update_password_action")}
              </ActionButton>
              <ActionButton
                type={"submit"}
                disabled={!canSubmit()}
                loading={loading}
              >
                {t("authentication-ui:employers_update_password_action")}
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
