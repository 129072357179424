import React from "react";
import {Col} from "react-bootstrap";
import "./JobDashboardApplicationsCount.scss";
import {Row} from "react-bootstrap";

function JobDashboardApplicationsCount(props) {
  const { label, count } = props;

  return (
    <Row className = "JobDashboardApplicationsCount">
      <Col className="count-label-col">{label}</Col>
      <Col className="count-col">{count}</Col>
    </Row>
  );
}

export default JobDashboardApplicationsCount;
