import {useCallback, useMemo, useState} from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useMutationJobUpdateDescription from "./useMutationJobUpdateDescription.js";
import { STEP_ENUM } from "./JobSaveForm.js";
import {
  formatWysiwygInput,
  formatWysiwygOutput,
  getWysiwygContentLength,
} from "../../common/widgets/WysiwygEditor.js";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm.js";
import JobSaveDescriptionForm from "./JobSaveDescriptionForm.js";
import useLazyQueryJobGenerateDescription from "../view/useLazyQueryJobGenerateDescription.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";

export default function useJobSaveDescriptionForm(job, isStepSaved) {
  const { t, loading: tLoading } = useTranslationJobs();
  const {
    mutate,
    loading: mutationLoading,
    errors: updateErrors,
  } = useMutationJobUpdateDescription();

  const [generating, setGenerating] = useState(false);
  const [generationErrors, setGenerationErrors] = useState(null);

  const {
    control,
    formState: { isValid },
    watch,
    setValue
  } = useValidatedInputForm();

  // Get number of characters typed in the wysiwyg (not counting hidden html tags)
  const description = watch("description");
  const textLength = getWysiwygContentLength(description);
  const submitting = mutationLoading;

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    return mutate({
      variables: {
        id: job._id,
        description: formatWysiwygOutput(description),
        lastSavedStep: isStepSaved ? undefined : STEP_ENUM.DESCRIPTION,
      },
    });
  }, [mutate, job._id, description, isStepSaved]);

  const { fetch: fetchDescription } = useLazyQueryJobGenerateDescription();

  const onLaunchGenerate = useCallback(() => {
    setGenerationErrors(null);
    setGenerating(true);
    return fetchDescription({
      id: job._id,
    }).then((description) => {
      const updateOptions = {shouldValidate: true, shouldDirty: true, shouldTouch: true};
      setValue("description", formatWysiwygInput(HtmlUtils.nlToParagraphString(description)), updateOptions);
    }).catch(errors => {
      setGenerationErrors(errors);
    }).finally(() => setGenerating(false));
  }, [fetchDescription, job, setValue]);

  const formProps = useMemo(() => ({
    t,
    control,
    job,
    loading: tLoading,
    submitting,
    textLength,
    onLaunchGenerate,
    generating
  }), [t, control, job, tLoading, submitting, textLength, onLaunchGenerate, generating]);

  return {
    form: JobSaveDescriptionForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting,
    errors: updateErrors || generationErrors,
  };
}
