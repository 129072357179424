import React from "react";
import OrganizationInlineEditInput from "./OrganizationInlineEditInput.js";
import InlineEditValidatedInputText from "../../common/widgets/InlineEditValidatedInputText.js";

/**
 * Generic component for all organization editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function OrganizationInlineEditInputText(props) {

  return <OrganizationInlineEditInput InlineEditInputComponent={InlineEditValidatedInputText} {...props}/>
};
