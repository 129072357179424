import React from "react";
import useTranslationCommon from "../useTranslationCommon.js";
import useShowLoader from "../loading-widgets/useShowLoader.js";
import "./WysiwygCounterDisplay.scss";

export default function WysiwygCounterDisplay(props) {
  const { maxLength, length } = props;
  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading);

  if (!tLoading) {
    return (
      <div className = "WysiwygCounterDisplay">
        <span className={length > maxLength ? "danger" : "classic"}>
          {length}
        </span>
        /{maxLength}
        <span className="character-word">{t("common:common_character_word")}</span>
      </div>
    );
  }
  return null;
}
