import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";

const QUERY_SCOPE = "jobAdmin";
const QUERY_NAME = "jobAdminMarkPaid";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $id: ID!,
        $paid: Boolean
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          id: $id,
          paid: $paid
        ) {
          _id
          paymentStatus
        }
      }
    }
  `;

export default function useMutationJobMarkPaid() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
