import React from "react";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import AdminTooltip from "./AdminTooltip.js";

export default function TruncateText({title, text, length}) {

  const excerpt = HtmlUtils.htmlToEllipsis(text, length);
  const truncated = excerpt.substr(-3) === "...";

  if (truncated)
    return <AdminTooltip title={title} text={text}>{excerpt}</AdminTooltip>;
  else
    return <span>{excerpt}</span>
};
