import React from "react";
import useFirebase from "../infra-no-ui/firebase/useFirebase.js";
import Firebase from "../infra-no-ui/firebase/Firebase.js";
import useTranslationAuthenticationNoUi from "./useTranslationAuthenticationNoUi.js";

export default function useDeleteUser() {

  const {t, loading: tLoading} = useTranslationAuthenticationNoUi();
  const firebase = useFirebase();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Sign out callback (use useCallback to avoid re-rendering of useSignOut consumers)
  const deleteUser = () => {
    setLoading(true);
    setError(null);

    // Ask Firebase to send an email to reset password
    return firebase
      .doDeleteUser()
      .catch(error => {
        const message = t(Firebase.getErrorTranslation(t, error.code)) + " (code: " + error.code + ")";
        setError(message);
        return Promise.reject(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return ({
    deleteUser,
    loading: loading || tLoading,
    error
  });
}
