import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryApplicationCount from "./useQueryApplicationCount.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import ApplicationApprovalStatusCountItem from "./ApplicationApprovalStatusCountItem.js";
import "./ApplicationCountItem.scss";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";

export default function ApplicationCountItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationCountItem");

  const { data: count, loading, errors } = useQueryApplicationCount();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="ApplicationCountItem">
            <PageSubtitle
              colTitle={t("admin:admin_dashboard_application_count_title")}
            />
            <Row>
              <Col className="application-count">
                <span>{count}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <ApplicationApprovalStatusCountItem
                  approvalStatus={CandidateApprovalStatusEnum.APPROVED}
                  statusLabel={t(
                    "admin:admin_dashboard_application_count_approved_title"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ApplicationApprovalStatusCountItem
                  approvalStatus={CandidateApprovalStatusEnum.PENDING}
                  statusLabel={t(
                    "admin:admin_dashboard_application_count_pending_title"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ApplicationApprovalStatusCountItem
                  approvalStatus={CandidateApprovalStatusEnum.REJECTED}
                  statusLabel={t(
                    "admin:admin_dashboard_application_count_rejected_title"
                  )}
                />
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
