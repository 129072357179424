import React, { useState } from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import { Breadcrumb } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import CandidatesList from "./CandidatesList.js";
import "./CandidatesBlock.scss";
import useQueryStatusGroupAndCount from "../search/useQueryStatusGroupAndCount.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import CandidatesFilter from "./CandidatesFilter.js";
import Alert from "react-bootstrap/Alert";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import JobPaymentStatusEnum from "../../jobs/JobPaymentStatusEnum.js";

function CandidatesBlock(props) {
  const { job, statusFilter } = props;
  const [filter, setFilter] = useState(statusFilter);

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesBlock");

  const {redirectTo} = useReferrer();

  const {
    data: counts,
    loading,
    errors,
  } = useQueryStatusGroupAndCount(job._id);

  function onClickDashboardLink(event, jobId) {
    event.preventDefault();
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_DASHBOARD, {
        jobId,
      })
    );
  }

  function onClickJobDashboardLink(event, jobId) {
    event.preventDefault();
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId,
      })
    );
  }

  React.useEffect(() => {
    setFilter(statusFilter);
  }, [statusFilter]);

  const pricingLinkComponent = <a href={ROUTES.PRICING} target="_blank" rel="noreferrer">{t("candidates:candidates_payment_alert_pricing_text")}</a>;
  const supportEmail =  process.env.REACT_APP_SUPPORT_EMAIL;
  const emailLinkComponent = <a href={"mailto:" + supportEmail}>{supportEmail}</a>
  const paymentAlertMsg = t("candidates:candidates_payment_alert_text", {
    postProcess: "reactPostprocessor",
    pricingLink: pricingLinkComponent,
    emailLink: emailLinkComponent
  });

  return (
    <div className="CandidatesBlock">
      <Row>
        <Breadcrumb>
          <Breadcrumb.Item>
            <ActionLink
              as={"div"}
              variant="outline-primary"
              onClick={(event) => onClickDashboardLink(event, job._id)}
              className={"CandidatesBlock"}
            >
              {t("candidates:candidates_dashboard_link")}
            </ActionLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ActionLink
              as={"div"}
              variant="outline-primary"
              onClick={(event) => onClickJobDashboardLink(event, job._id)}
              className={"CandidatesBlock"}
            >
              {job.title}
            </ActionLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t("candidates:candidates_label")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col>
          <h1>{job.title}</h1>
        </Col>
      </Row>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row>
            <Col className="filter-col">
              <CandidatesFilter
                filter={filter}
                counts={counts}
                onFilterClick={setFilter}
              />
            </Col>
          </Row>
        )}
      />
      <Row>
        <Col>
          {job.paymentStatus === JobPaymentStatusEnum.PENDING ?
            <Alert variant={"danger"}>{paymentAlertMsg}</Alert>
            :
            <CandidatesList
              jobId={job._id}
              status={filter}
              onChangeStatus={setFilter}
            />
          }
        </Col>
      </Row>
    </div>
  );
}

export default CandidatesBlock;
