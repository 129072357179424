import StringUtils from "../utils/StringUtils.js";

// Try to keep declarations here and in App.js in the same order, to improve maintainability
export const CONNECTION_MODE = "/connectionmode";
export const CONNECTION_MODE_SIGN_UP = "/connectionmode-signup";
export const SIGN_OUT = "/signout";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const HOME = "/";

export const EMPLOYER_DASHBOARD = "/employer/dashboard";
export const EMPLOYER_ACTIVE_JOBS = "/employer/jobs";
export const EMPLOYER_JOB_CREATE = "/employer/save-job";
export const EMPLOYER_JOB_DASHBOARD = "/employer/jobs/:jobId/dashboard";
export const EMPLOYER_CANDIDATES = "/employer/candidates";
export const EMPLOYER_JOB_CANDIDATES = "/employer/jobs/:jobId/candidates";
export const EMPLOYER_CANDIDATE_PROFILE = "/employer/jobs/:jobId/:candidateId";
export const EMPLOYER_JOB_UPDATE = "/employer/jobs/:jobId/update";
export const EMPLOYER_RECRUITER_PROFILE = "/employer/recruiters/:recruiterId";
export const EMPLOYER_PROFILE = "/employer/profile";
export const EMPLOYER_CHATGPT = "/employer/chatgpt";

export const PUBLIC_URL = StringUtils.stripTrailingSlash(
  process.env.REACT_APP_PUBLIC_URL
);
export const CONTACT_US = PUBLIC_URL + "/contact-us";
export const PRIVACY = PUBLIC_URL + "/privacy";
export const CAREERS = PUBLIC_URL + "/careers";
export const TERMS = PUBLIC_URL + "/terms";
export const ABOUTUS = PUBLIC_URL + "/about-us";
export const PRICING = PUBLIC_URL + "/pricing";
export const EMPLOYER_ADMIN = "/admin";
export const EMPLOYER_ADMIN_PAYMENT = "/admin/payment";
export const EMPLOYER_ADMIN_APPLICATION_COUNT_BY_JOB =
  "/admin/application-count-by-job";
export const EMPLOYER_ADMIN_RECRUITERS = "/admin/recruiters";
export const EMPLOYER_ADMIN_APPLICATIONS = "/admin/applications";
export const EMPLOYER_ADMIN_EMPLOYERS = "/admin/employers";
export const EMPLOYER_ADMIN_JOBS = "/admin/jobs";

export const PUBLIC_JOB_URL = PUBLIC_URL + "/jobs/:jobId";

//export const PASSWORD_CHANGE = "/pw-change";
export const RESET_PASSWORD = "/pw-forget";

export const NOT_FOUND = "/not-found";

// error page for tests
export const ERROR_TEST = "/error-test";

//liens
export const RECRUITER_PORTAL = StringUtils.stripTrailingSlash(
  process.env.REACT_APP_PUBLIC_URL
);

export const RECRUITER_SIGN_IN = RECRUITER_PORTAL + "/signin";
export const RECRUITER_SIGN_UP = RECRUITER_PORTAL + "/signup";

//liens
export const FACEBOOK_URL = "https://www.facebook.com/Mouseatwork";
export const LINKEDIN_URL = "https://linkedin.com/company/mouse-at-work";
