import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useMemo} from "react";
import StringUtils from "../../../utils/StringUtils.js";

/**
 * Utilities to keep the referrer when navigating from one page to another using React Router.
 * Some React Router components such as Redirect keep the referrer in the location.state.referrer.
 * Other components and hooks (such as history.push) do not. Use "redirectTo" instead of history.push
 * so that the referrer is propagated.
 * When navigating back to the referrer page, typically after Sign In or Sign Up, use the "fallback"
 * (eg. HOME) when there is no referrer information.
 */
export default function useReferrer(fallback) {

  const location = useLocation();
  const navigate = useNavigate();

  // Do not use the whole location object as dependency, many parts of it (namely key and state) change and that provokes an infinite render loop
  const locationStateReferrerPathname = location.state && location.state.referrer ? location.state.referrer.pathname : null;
  const locationStateReferrerSearch = location.state && location.state.referrer ? location.state.referrer.search : null;
  const fallbackReferrer = useMemo(() => ({pathname: fallback}), [fallback]);
  const locationStateReferrer = useMemo(() => ({
    pathname: locationStateReferrerPathname,
    search: locationStateReferrerSearch
  }), [locationStateReferrerPathname, locationStateReferrerSearch]);

  const locationPathname = location.pathname;
  const locationSearch = location.search;

  // Make a redirection target state containing the current page as the referrer (usual behaviour)
  const referrerStateWithCurrent = useMemo(() =>
      makeReferrerState(locationPathname, locationSearch),
    [locationPathname, locationSearch]);

  // Make a redirection target state containing the previous page as the referrer (ie. do not put the current page in history)
  const referrerStateWithPrevious = useMemo(() =>
      makeReferrerState(locationStateReferrer.pathname, locationStateReferrer.search)
    , [locationStateReferrer]);

  const referrer = useMemo(() => {
    const locationAsString = locationPathname + locationSearch;
    const referrerAsString = StringUtils.nullToEmpty(locationStateReferrerPathname) + StringUtils.nullToEmpty(locationStateReferrerSearch);
    // Referrer is taken from the route, unless it is empty or it refers to the current page
    return (locationStateReferrerPathname && locationAsString !== referrerAsString) ? locationStateReferrer : fallbackReferrer;
  }, [locationStateReferrer, locationStateReferrerPathname, locationStateReferrerSearch, locationPathname, locationSearch, fallbackReferrer])

  const redirectToReferrer = useCallback(() => {
    const {state, ...to} = referrer;
    navigate(to, {state});
  }, [navigate, referrer]);

  const redirectTo = useCallback((target, updateReferrer = true) => {
    navigate(target, {state: updateReferrer ? referrerStateWithCurrent : referrerStateWithPrevious});
  }, [navigate, referrerStateWithCurrent, referrerStateWithPrevious]);

  return useMemo(() => ({
    referrer,
    redirectToReferrer,
    redirectTo,
    referrerStateWithCurrent,
    referrerStateWithPrevious
  }), [referrer, redirectToReferrer, redirectTo, referrerStateWithCurrent, referrerStateWithPrevious]);
}

const makeReferrerState = (referrerPathname, referrerSearch) => {
  return {
    state: {
      referrer: {
        pathname: referrerPathname,
        search: referrerSearch
      }
    }
  };
};
