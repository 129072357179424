import React from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import StringUtils from "../../utils/StringUtils.js";
import FormText from "react-bootstrap/FormText";
import ActionButton from "../common/widgets/ActionButton.js";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import useSignIn from "../authentication-no-ui/useSignIn.js";
import * as ROUTES from "../../constants/routes.js";
import useReferrer from "../infra-no-ui/navigation/useReferrer.js";
import { Navigate } from "react-router-dom";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
//import SignInWithAuthProvider from "./SignInWithAuthProvider.js";
import ActionLink from "../common/widgets/ActionLink.js";
import Separator from "../common/widgets/Separator.js";
import ValidatedInputEmail from "../common/widgets/ValidatedInputEmail.js";
import ValidatedInputText from "../common/widgets/ValidatedInputText.js";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm.js";
import { MIN_LENGTH_PASSWORD } from "../../constants/ui.js";
import { useTranslation } from "react-i18next";
import RouterUtils from "../../utils/RouterUtils.js";
import "./SignInForm.scss";

export default function SignInForm() {
  const { signIn, loading, error, ready: signInReady } = useSignIn();
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const { referrer } = useReferrer(ROUTES.HOME);
  const {redirectTo} = useReferrer();
  const { i18n } = useTranslation();

  useShowLoader(tLoading || !signInReady, "SignInForm");

  const onClickSignUp = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.SIGN_UP);
  };

  const onClickResetPassword = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.RESET_PASSWORD);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const fields = getValues();
    signIn(fields.email.trim(), fields.password);
  };

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  // RENDER
  React.useEffect(() => {
    // If already logged, force the default user language
    if (authUser.authenticated) {
      i18n.changeLanguage(
        authUser.profile.defaultLanguage.toLowerCase(),
        (/*err*/) => {
          // Do nothing if an error occurs
        }
      );
    }
  }, [authUser, i18n]);

  function onClickRecruiterSignIn(event) {
    event.preventDefault();
    RouterUtils.openExternalUrl(ROUTES.RECRUITER_SIGN_IN);
  }

  // If already logged, or when rendering after sign in, do not display this page, redirect to referrer or HOME
  if (authUser.authenticated) {
    return <Navigate replace to={referrer} />;
  }

  function renderForm() {
    // For some reason that is internal to Firebase, StyledFirebaseAuth rendered by SignInWithAuthProvider does not re-render
    // when the button labels change, so make sure the form is not displayed until the translation is ready.
    // A more robust solution needs to be found because labels still don't change when language changes
    if (tLoading) return null;

    return (
      <div className="SignInForm">
        {/*
        <div>
          <Col>
            <SignInWithAuthProvider
              googleButtonLabel={t("authentication-ui:sign_in_with_google")}
              facebookButtonLabel={t("authentication-ui:sign_in_with_facebook")}
              target={referrer.pathname}
            />
          </Col>
        </div>
        */}
        <Row>
          <Col className="separator-col">
            <Separator>
              {t("authentication-ui:sign_in_with_email_msg")}
            </Separator>
          </Col>
        </Row>
        <Row className="input-row-container">
          <Col className="input-col-container">
            <Form onSubmit={onSubmit} className={"form-with-rows"}>
              <FormGroup controlId="email">
                <ValidatedInputEmail
                  required={true}
                  name="email"
                  control={control}
                  className="FormControl"
                  autoFocus
                  type="email"
                  placeholder={t("authentication-ui:sign_in_email_placeholder")}
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup controlId="password">
                <ValidatedInputText
                  minLength={MIN_LENGTH_PASSWORD}
                  className="FormControl"
                  control={control}
                  required={true}
                  name="password"
                  type="password"
                  placeholder={t(
                    "authentication-ui:sign_in_password_placeholder"
                  )}
                  autoComplete="current-password"
                />
              </FormGroup>
              <FormGroup>
                <div className="form-control-row">
                  <ActionButton
                    loading={loading}
                    type="submit"
                    disabled={!canSubmit()}
                  >
                    {t("authentication-ui:sign_in_sign_in_button")}
                  </ActionButton>
                </div>
              </FormGroup>
              {!StringUtils.isNullOrEmpty(error) && (
                <FormText as="div" className="form-error">
                  {error}
                </FormText>
              )}
            </Form>
          </Col>
        </Row>
        <Row className="action-links-row">
          <Col className="action-links-col">
            <Row>
              <Col className="forgot-col">
                <ActionLink onClick={onClickResetPassword}>
                  {t("authentication-ui:sign_in_forgot_password_link")}
                </ActionLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            {t("authentication-ui:sign_in_sign_up_question")}
            <ActionLink className="sign-up-action" onClick={onClickSignUp}>
              {t("authentication-ui:sign_in_sign_up_link")}
            </ActionLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActionLink
              className="sign-in-action"
              onClick={(event) => onClickRecruiterSignIn(event)}
            >
              {t("authentication-ui:sign_in_not_employer")}
            </ActionLink>
          </Col>
        </Row>
      </div>
    );
  }

  return <div className="SignInForm">{renderForm()}</div>;
}
