import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationRecruiters from "../useTranslationRecruiters.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import useMutationRecruiterFeedbackCreate from "./useMutationRecruiterFeedbackCreate.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import StringUtils from "../../../utils/StringUtils.js";
import useFeedbackModal from "../../common/modals/useFeedbackModal.js";
import ReactStars from "react-rating-stars-component";
import "./RecruiterFeedbackCreateForm.scss";

export default function RecruiterFeedbackCreateForm(props) {
  const { onClose, application, employerId } = props;
  const recruiterId = application.recruiterId;

  const { t, loading: tLoading } = useTranslationRecruiters();
  useShowLoader(tLoading);

  const {
    mutate,
    loading: mutationLoading,
    errors: mutationErrors,
  } = useMutationRecruiterFeedbackCreate();

  const errorMsg = useServerErrorFormatter(mutationErrors);

  const { launch, FeedbackModal } = useFeedbackModal({errorMsg});

  const [feedback, setFeedback] = React.useState("");
  const [rating, setRating] = React.useState(0);
  const createRecruiterFeedback = () => {
    return mutate({
      variables: {
        recruiterId: recruiterId,
        employerId: employerId,
        feedback: feedback.trim(),
        rating: rating,
      },
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    launch(createRecruiterFeedback, onClose);
  };

  const canSubmit = () => {
    return !StringUtils.isNullOrEmpty(feedback) || rating > 0;
  };

  const ratingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <>
      {FeedbackModal}
      <div className="RecruiterFeedbackCreateForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <h1>
                {application.recruiter.firstName}{" "}
                {application.recruiter.lastName}
              </h1>
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>{t("recruiters:recruiters_add_rating_label")}</Col>
          </Row>
          <Row>
            <Col className={"rating-row"}>
              <ReactStars
                edit={true}
                count={5}
                value={rating}
                size={36}
                onChange={ratingChange}
              />
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>{t("recruiters:recruiters_add_feedback_label")}</Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                name="feedback"
                as="textarea"
                rows="3"
                value={feedback}
                onChange={(event) =>
                  setFeedback(StringUtils.superTrim(event.target.value))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButton
                loading={mutationLoading}
                type={"submit"}
                disabled={!canSubmit()}
              >
                {t("recruiters:recruiters_add_feedback_submit_action")}
              </ActionButton>
              <ActionButton onClick={onClose}>
                {t("recruiters:recruiters_add_feedback_cancel_action")}
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
