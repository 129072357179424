import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "applicationAdmin";
const QUERY_NAME = "applicationAdminSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($pageSize: Int, $filter: ApplicationAdminFilterInput, $sort: ApplicationSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          candidate {
            _id
            name
          }
          recruiter {
            _id
            firstName,
            lastName,
          }
          job {
            _id
            title
            companyName
          }   
          recruiterNote
          status
          viewed
          candidateApprovalStatus
          updatedAt
        }
      }
    }
  }
`;

export default function useQueryApplicationAdminSearch(pageSize, filter, sort) {
  const variables = { pageSize, filter, sort };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
