import React, {useCallback} from "react";
import Page from "../../common/page/Page.js";
import {useLocation, useParams} from "react-router-dom";
import queryString from "query-string";
import useQueryJobById from "../view/useQueryJobById.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import ScrollUtils from "../../common/page/ScrollUtils.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import useTranslationJobs from "../useTranslationJobs.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import * as ROUTES from "../../../constants/routes.js";
import JobSaveForm from "./JobSaveForm.js";
import ActionDiv from "../../common/widgets/ActionDiv.js";

export default function JobUpdatePage(/*props*/) {

  const { t, loading: tLoading } = useTranslationJobs();
  const location = useLocation();
  const {redirectTo} = useReferrer();
  const params = useParams();
  const { profile } = useAuthUser();

  // Current step
  const step = queryString.parse(location.search).step;

  // JobId, if present (when not present, it's to create a job, otherwise it's to update the job)
  const jobId = queryString.parse(location.search).jobId || params.jobId;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // Pass a new jobId after the job is created, leave it undefined when job already exists
  const changeStep = useCallback((step, newJobId = undefined) => {
    redirectTo({
      pathname: location.pathname,
      search: queryString.stringify({step, jobId: newJobId || jobId})
    });
    ScrollUtils.scrollToTop();
  }, [redirectTo, location.pathname, jobId]);

  // Load existing job, if jobId is provided
  const {data: existingJob, loading, errors} = useQueryJobById(jobId);

  // Make a fake job
  const employerId = profile._id;
  const newJob = React.useMemo(() => ({
    employerId,
    organization: {}
  }), [employerId]);

  // Select between existing job and new job
  const job = existingJob || newJob;

  const goToJob = React.useCallback(() => {
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId: job._id,
      })
    );
  }, [redirectTo, job]);

  const onCompleted = React.useCallback(() => {
   goToJob();
  }, [goToJob]);

  const pageTitle = existingJob ? job.title : t("jobs:employers_create_job_title");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={loading || tLoading}
          errors={errors}
          onLoaded={() => (
            <Page className="JobUpdatePage">
              <Page.Title loading={loading}><ActionDiv onClick={() => goToJob()}>{pageTitle}</ActionDiv></Page.Title>
              <Page.Content>
                <JobSaveForm
                  job={job}
                  step={step}
                  onChangeStep={changeStep}
                  onCompleted={onCompleted}
                />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
