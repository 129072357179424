import React, {useCallback, useEffect} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationChatGpt from "../useTranslationChatGpt.js";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import StringUtils from "../../../utils/StringUtils.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import {useFormFields} from "../../infra-no-ui/react-helpers/useFormFields.js";
import TypeUtils from "../../../utils/TypeUtils.js";

const initializeFields = (params) => {
  const initialFields = {};
  TypeUtils.ensureArray(params).forEach(param => {
    if (!StringUtils.isNullOrEmpty(param.name))
      initialFields[param.name] = StringUtils.nullToEmpty(param.value);
  });
  return initialFields;
}

const fieldsToParams = (fields, names) => {
  const newParams = [];
  TypeUtils.ensureArray(names).forEach(name => {
    if (!StringUtils.isNullOrEmpty(name))
      newParams.push({
        name,
        value: fields[name]
      });
  });
  return newParams;
}

const getParamPlaceholder = (name, params) => {
  const param = TypeUtils.ensureArray(params).find(p => p.name === name);
  return (param && StringUtils.nullToEmpty(param.placeholder)) || "";
}

export default function StandardPromptForm({template, onAbort, onSuccess, params}) {

  const { t, loading: tLoading } = useTranslationChatGpt();
  useShowLoader(tLoading);

  const [fields, handleFieldChange] = useFormFields(initializeFields(params));

  const paramNames = TypeUtils.ensureArray(params).map(param => param.name);

  useEffect(() => {
    initializeFields(params);
  }, [params]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    onSuccess(fieldsToParams(fields, paramNames));
  }, [onSuccess, fields, paramNames]);

  /*
  const canSubmit = useCallback(() =>
      paramNames.every(name => fields[name] !== ""),
    [paramNames, fields]
  );
  */

  const canSubmit = useCallback(() => true, []);

  return (
    <>
      <div className="StandardPromptForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <h1>{template}</h1>
            </Col>
          </Row>
          {paramNames.map(name =>
            <Row key={name}>
              <Col>
                <FormGroup controlId={name}>
                  <FormControl
                    className="FormControl"
                    autoFocus
                    value={StringUtils.nullToEmpty(fields[name])}
                    onChange={handleFieldChange}
                    placeholder={getParamPlaceholder(name, params)}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col className={"form-actions"}>
              <ActionButton
                type={"submit"}
                disabled={!canSubmit()}
              >
                {t("chatgpt:standard_prompt_submit")}
              </ActionButton>
              <ActionButton onClick={onAbort}>
                {t("chatgpt:standard_prompt_cancel")}
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
