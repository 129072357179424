import React, {useCallback} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Link} from "react-router-dom";
import * as ROUTES from "../../../constants/routes.js";
import RecruiterCountItem from "./RecruiterCountItem.js";
import RecruiterCountByDateItem from "./RecruiterCountByDateItem.js";
import EmployerCountItem from "./EmployerCountItem.js";
import EmployerCountByDateItem from "./EmployerCountByDateItem.js";
import ApplicationCountItem from "./ApplicationCountItem.js";
import ApplicationCountByDateItem from "./ApplicationCountByDateItem.js";
import RefreshButton from "../../common/widgets/RefreshButton.js";
import "./AdminBlock.scss";
import ActionDiv from "../../common/widgets/ActionDiv.js";
import JobPublicationCountByDateItem from "./JobPublicationCountByDateItem.js";
import JobCountItem from "./JobCountItem.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

function AdminBlock(/*props*/) {

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "AdminBlock");

  const {redirectTo, referrerStateWithCurrent} = useReferrer();

  const goToAllRecruiters = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_RECRUITERS);
  }, [redirectTo]);

  const goToAllApplications = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_APPLICATIONS);
  }, [redirectTo]);

  const goToAllEmployers = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_EMPLOYERS);
  }, [redirectTo]);

  const goToAllJobs = useCallback(() => {
    redirectTo(ROUTES.EMPLOYER_ADMIN_JOBS);
  }, [redirectTo]);

  return (
    <div className="AdminBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_dashboard_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <Row>
            <Col>
              <Link to={ROUTES.EMPLOYER_ADMIN_PAYMENT} state={referrerStateWithCurrent}>
                {t("admin:admin_dashboard_payment_link")}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={ROUTES.EMPLOYER_ADMIN_APPLICATION_COUNT_BY_JOB} state={referrerStateWithCurrent}>
                {t("admin:admin_dashboard_application_count_by_job_link")}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllRecruiters()}><RecruiterCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <RecruiterCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllEmployers()}><EmployerCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <EmployerCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllApplications()}><ApplicationCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <ApplicationCountByDateItem />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <ActionDiv className="inflate" onClick={() => goToAllJobs()}><JobCountItem /></ActionDiv>
        </Col>
        <Col className="widget-block">
          <JobPublicationCountByDateItem />
        </Col>
      </Row>
    </div>
  );
}

export default AdminBlock;
