import React from "react";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import useContentModal from "../common/modals/useContentModal.js";
import ActionLink from "../common/widgets/ActionLink.js";
import UpdatePasswordForm from "./UpdatePasswordForm.js";
import useTranslationCandidates from "../candidates/useTranslationCandidates.js";
import useSignOut from "../authentication-no-ui/useSignOut.js";

export default function UpdatePasswordButton(props) {

  const { t, loading: tLoading } = useTranslationCandidates();
  const { signOut, loading, ready: signOutReady } = useSignOut();

  useShowLoader(loading || !signOutReady || tLoading, "UpdatePasswordButton");

  const { ContentModal: UpdatePasswordModal, show: showUpdatePasswordModal, hide: hideUpdatePasswordModal } = useContentModal();


  const onClickUpdate = () => {
    showUpdatePasswordModal(updatePasswordForm);
  }

  const onPasswordUpdated = () => {
    hideUpdatePasswordModal();
    signOut();
  };

  const updatePasswordForm = <UpdatePasswordForm onAbort={hideUpdatePasswordModal} onSuccess={onPasswordUpdated} />;

  return (
    <>
      {UpdatePasswordModal}
      <ActionLink onClick={onClickUpdate} >{t("authentication-ui:employers_profile_update_password")}</ActionLink>
    </>
  );
}
