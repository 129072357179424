import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryEmployerCount from "./useQueryEmployerCount.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import "./EmployerCountItem.scss";

export default function EmployerCountItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "EmployerCountItem");

  const { data: count, loading, errors } = useQueryEmployerCount();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="EmployerCountItem">
            <PageSubtitle
              colTitle={t("admin:admin_dashboard_employers_count_title")}
            />
            <Row>
              <Col className="employer-count">
                <span>{count}</span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
