import React, {useCallback, useMemo} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import ButtonGroup from "../../common/components/ButtonGroup.js";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";
import TypeUtils from "../../../utils/TypeUtils.js";

const ALL = "ALL";

function CandidatesFilter(props) {
  const { filter, counts, onFilterClick } = props;

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesFilter");

  const totalCount = useMemo(() => {
    return TypeUtils.ensureArray(counts).reduce((sum, count) => sum + count["count"], 0);
  }, [counts]);

  const getCountForStatus = (status) => {
    const statusItem = counts.find((item) => item.status === status);
    return statusItem ? statusItem["count"] : 0;
  };

  const localOnFilterClick = useCallback(filter => {
    const standardStatus = filter === ALL ? "" : filter;
    onFilterClick(standardStatus);
  }, [onFilterClick]);

  const filterLabels = [
    {
      id: ALL,
      label: t("candidates:candidates_status_all_label", {
        nb: totalCount,
      }),
    },
    {
      id: ApplicationStatusEnum.STACK,
      label: t("candidates:candidates_status_stack_label", {
        nb: getCountForStatus(ApplicationStatusEnum.STACK),
      }),
    },
    {
      id: ApplicationStatusEnum.REJECTED,
      label: t("candidates:candidates_status_rejected_label", {
        nb: getCountForStatus(ApplicationStatusEnum.REJECTED),
      }),
    },
    {
      id: ApplicationStatusEnum.SHORTLIST,
      label: t("candidates:candidates_status_shortlist_label", {
        nb: getCountForStatus(ApplicationStatusEnum.SHORTLIST),
      }),
    },
    {
      id: ApplicationStatusEnum.HIRED,
      label: t("candidates:candidates_status_hired_label", {
        nb: getCountForStatus(ApplicationStatusEnum.HIRED),
      }),
    },
    {
      id: ApplicationStatusEnum.HIRING_CANCELLED,
      label: t("candidates:candidates_status_hiring_cancelled_label", {
        nb: getCountForStatus(ApplicationStatusEnum.HIRING_CANCELLED),
      }),
    },
  ];

  return (
    <>
      <ButtonGroup
        buttons={filterLabels}
        selectedId={filter || ALL}
        onClick={localOnFilterClick}
      />
    </>
  );
}

export default CandidatesFilter;
