import React from "react";
import ErrorIcon from "@mui/icons-material/ReportProblemOutlined";
import "./LoadingError.scss";
import StringUtils from "../../../utils/StringUtils.js";
import useTranslationCommon from "../useTranslationCommon.js";
import useShowLoader from "./useShowLoader.js";

export default function LoadingError(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "LoadingError");

  // RENDER

  return (
    <div className={"LoadingError " + StringUtils.nullToEmpty(props.className)}>
      <div>
        <ErrorIcon className="error-icon" />
      </div>
      <div>{t("common:loading_data_error")}</div>
    </div>
  );
}
