import React from "react";
import Page from "../../common/page/Page.js";
import JobDashboardBlock from "./JobDashboardBlock.js";
import useQueryJobById from "../../jobs/view/useQueryJobById.js";
import { useParams } from "react-router";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useTranslationJobDashboard from "../useTranslationJobDashboard.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";

export default function JobDashboardPage(props) {
  // Fetch job from ID provided in the url params.
  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "JobDashboardPage");

  const urlParams = useParams();
  const jobId = urlParams.jobId;
  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={jobLoading}
          errors={jobErrors}
          onError={() => <ReportProblemOutlined className="LoadingError" />}
          onLoaded={() => (
            <Page className="JobCandidatesPage">
              {!job && (
                <>
                  <Page.Title>
                    {t("job-dashboard:job_dashboard_deleted_job_msg")}
                  </Page.Title>
                </>
              )}
              {job && (
                <>
                  <Page.Title>{job.title}</Page.Title>
                  <Page.Content>
                    <JobDashboardBlock job={job} />
                  </Page.Content>
                </>
              )}
            </Page>
          )}
        />
      )}
    />
  );
}
