import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./RecruiterFeedback.scss";import useContentModal from "../../common/modals/useContentModal.js";
import RecruiterFeedbackCreateForm from "../mutate/RecruiterFeedbackCreateForm.js";
import RecruiterFeedbackUpdateForm from "../mutate/RecruiterFeedbackUpdateForm.js";
import useTranslationRecruiters from "../useTranslationRecruiters.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ActionDiv from "../../common/widgets/ActionDiv.js";

export default function RecruiterFeedbackLink(props) {
  const { application, employerId, comments } = props;

  const { t, loading: tLoading } = useTranslationRecruiters();
  useShowLoader(tLoading, "RecruiterFeedback");

  const employerFeedback = comments.filter((item) => {
    return item.employerId === employerId;
  });

  // Modal to add a feedback
  const {
    ContentModal: RecruiterFeedbackCreateModal,
    show: showRecruiterFeedbackCreateModal,
    hide: hideRecruiterFeedbackCreateModal,
  } = useContentModal("RecruiterFeedbackCreateForm");

  const onClickCreateFeedback = () => {
    showRecruiterFeedbackCreateModal(getRecruiterFeedbackCreateForm());
  };

  const getRecruiterFeedbackCreateForm = () => {
    return (
      <RecruiterFeedbackCreateForm
        onClose={hideRecruiterFeedbackCreateModal}
        application={application}
        employerId={employerId}
      />
    );
  };

  // Modal to update a feedback
  const {
    ContentModal: RecruiterFeedbackUpdateModal,
    show: showRecruiterFeedbackUpdateModal,
    hide: hideRecruiterFeedbackUpdateModal,
  } = useContentModal("RecruiterFeedbackUpdateForm");

  const onClickUpdateFeedback = () => {
    showRecruiterFeedbackUpdateModal(getRecruiterFeedbackUpdateForm());
  };

  const getRecruiterFeedbackUpdateForm = () => {
    return (
      <RecruiterFeedbackUpdateForm
        onClose={hideRecruiterFeedbackUpdateModal}
        application={application}
        employerFeedback={employerFeedback[0]}
      />
    );
  };

  return (
    <>
      {RecruiterFeedbackCreateModal}
      {RecruiterFeedbackUpdateModal}
      <Row>
        <Col >
          {employerFeedback.length === 0 && (
            <ActionDiv className="action-col" onClick={() => onClickCreateFeedback()}>
              {t("recruiters:recruiters_add_feedback_label")}
            </ActionDiv>
          )}
          {employerFeedback.length > 0 && (
            <ActionDiv className="action-col"onClick={ () => onClickUpdateFeedback()}>
              {t("recruiters:recruiters_update_feedback_label")}
            </ActionDiv>
          )}
        </Col>
      </Row>
    </>
  );
}
