import React from "react";
import { FormControl } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils.js";
import "./InlineEditInputTextArea.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import InlineEditBase from "./InlineEditBase.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents.js";
import InlineEditInputButtons from "./InlineEditInputButtons.js";

/**
 * Input box that display the value as read only until we click the Edit icon next to the label.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputTextArea(props) {
  const { className, ...otherProps } = props;

  return (
    <InlineEditBase
      {...otherProps}
      formatValue={HtmlUtils.nlToBr}
      EditComponent={WithOnBlurInputTextEditComponent}
      className={["InlineEditInputTextArea", className].join(" ")}
      multilineView
    />
  );
}

// Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
const WithOnBlurInputTextEditComponent = withOnBlur()(
  InputTextAreaEditComponent
);

function InputTextAreaEditComponent(props) {
  const {
    viewAs,
    placeholder,
    value,
    onSubmit,
    validate,
    onCancel,
    setBlurListener,
    unsetBlurListener,
  } = props;
  const focusRef = React.useRef();

  const formatOutput = React.useCallback((output) => {
    return StringUtils.nullToEmpty(output).trim();
  }, []);

  const { currentValue, submit, cancel, changeValue, validationError } =
    useInlineEditInputEvents(
      value,
      onSubmit,
      validate,
      onCancel,
      setBlurListener,
      unsetBlurListener,
      focusRef,
      null,
      formatOutput
    );

  const hasError = !StringUtils.isNullOrEmpty(validationError);

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  };

  // User types a letter in the input box
  const onChange = (event) => {
    changeValue(event.target.value);
    // Don't call validate here, because the current value might not be rendered yet.
  };

  // Cancel button is clicked
  const onClickCancel = (event) => {
    event.preventDefault();
    cancel();
  };

  return (
    <Form onSubmit={onLocalSubmit} className={"InputTextAreaEditComponent"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <FormControl
            className={"textarea"}
            as={"textarea"}
            type="text"
            value={currentValue}
            onChange={onChange}
            placeholder={placeholder}
            size={viewAs === "h1" ? "lg" : "text"}
            ref={focusRef}
          />
        </Col>
      </Row>
      <Row className={"button-row"}>
        <Col>
          <InlineEditInputButtons onClick={onClickCancel} disabled={hasError} />
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </Form>
  );
}
