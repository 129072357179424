import React, {useState} from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useQueryJobsTitles from "./useQueryJobsTitles.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import SearchBox from "../../common/components/SearchBox.js";
import JobSortEnum from "../JobSortEnum.js";
import StringUtils from "../../../utils/StringUtils.js";
import {getJobStatusLabel} from "../JobStatusEnum.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function JobSearchBox(props) {
  const { employerId, status } = props;
  const [searchValue, setSearchValue] = useState("");

  const {
    data: results,
    loading: jobsLoading,
    errors: jobsErrors,
  } = useQueryJobsTitles(
    5,
    { employerId, status, title: searchValue },
    JobSortEnum.TITLE_ASC
  );

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchBox");
  const {redirectTo} = useReferrer();

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: jobsLoading,
      errors: jobsErrors,
    };
  };

  const onSelectOption = (option) => {
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.EMPLOYER_JOB_DASHBOARD, {
        jobId: option._id,
      })
    );
  };

  const errorMsg = t("jobs:employers_search_filter_error");
  const emptyMsg = t("jobs:employers_search_filter_empty");
  const placeholder = t("jobs:employers_search_filter_placeholder");

  // Real-time search is performed on title but display job city and status, to disambiguate jobs with same titles
  // Eg.: Plumber - Montreal - ARCHIVED
  const makeLabelKey = React.useCallback(option => {
    if (tLoading)
      return "";

    const parts = [option.title];
    if (!StringUtils.isNullOrEmpty(option.city))
      parts.push(option.city)
    parts.push(getJobStatusLabel(t, option.status).toLocaleUpperCase())

    return parts.join(" - ");
  }, [t, tLoading]);

  return (
    <SearchBox
      className={"JobSearchBox"}
      useQueryOptions={useQueryOptions}
      onSelectOption={onSelectOption}
      errorMsg={errorMsg}
      emptyMsg={emptyMsg}
      placeholder={placeholder}
      labelKey={makeLabelKey}
      setSearchValue={setSearchValue}
    />
  );
}
