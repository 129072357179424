import React from "react";
import "./JobSearchItem.scss";
import Card from "react-bootstrap/Card";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import StringUtils from "../../../utils/StringUtils.js";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import DateRangeIcon from "@mui/icons-material/DateRange.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import DisplayDate from "../../common/widgets/DisplayDate.js";
import TypeUtils from "../../../utils/TypeUtils.js";

export default function JobSearchItem(props) {
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchItem");

  const { item: job } = props;

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  const onClickViewJob = () => {
    const jobTab = window.open(
      RouterUtils.injectParamsInRoute(ROUTES.PUBLIC_JOB_URL, {
        jobId: job._id,
      }),
      "_blank"
    );
    jobTab.focus();
  };

  // RENDER
  return (
    <Card className="JobSearchItem" id={job._id}>
      <Card.Body>
        <Row>
          <Col className="job-information-col">
            <Row>
              <Col>
                <ActionLink onClick={onClickViewJob} className="name-col">
                  {job.title}
                </ActionLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="logo-col">
                    <BusinessIcon />
                  </Col>
                  <Col>{job.employer && job.employer.company}</Col>
                </Row>

                {!StringUtils.isNullOrEmpty(addressLine) && (
                  <Row>
                    <Col className="logo-col">
                      <PlaceIcon />
                    </Col>
                    <Col>{addressLine}</Col>
                  </Row>
                )}
                <Row>
                  <Col className="logo-col">
                    <DateRangeIcon />
                  </Col>
                  <Col>
                    <DisplayDate date={job.createdAt} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="last-col">
            <Row>
              <Col className="commission-title-col">
                {t("jobs:employers_job_details_commission_title")}
              </Col>
            </Row>
            <Row>
              <Col className="commission-col">${job.commission}</Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
