import React from "react";
import Page from "../../common/page/Page.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import PaymentBlock from "./PaymentBlock.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";


export default function PaymentPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "PaymentPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="PaymentPage">
          <Page.Title text={t("admin:admin_payment_page_title")} loading={tLoading} />
          <Page.Content>
            <PaymentBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
