import React from "react";
import "./ActionIcon.scss";
import Button from "react-bootstrap/Button";
import ActionLoadingComponent from "./ActionLoadingComponent.js";

/**
 * Display an icon as a button. Accepts all properties for Button components, plus a boolean "loading" state.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionIcon(props) {
  const { loading, className, classNameIcon, icon: Icon, ...otherProps } = props;

  const newClassName = ["ActionIcon", className].join(" ");

  return (
      <Button
        {...otherProps}
        className={newClassName}
        disabled={loading}
        variant={"link"}
      >
        <ActionLoadingComponent loading={loading}>
          <Icon className={classNameIcon} />
        </ActionLoadingComponent>
      </Button>
  );
}
