import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useQueryApplicationCount from "../view/useQueryApplicationCount.js";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";

export default function ApprovedApplicationByRecruiterCountItem(props) {
  const { recruiterId } = props;
  const { data: count, loading, errors } = useQueryApplicationCount({recruiterId, candidateApprovalStatus: CandidateApprovalStatusEnum.APPROVED});

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <Row>
            <Col>
              <span>{count}</span>
            </Col>
          </Row>
        )}
      />
    </HorizontalLoader>
  );
}
