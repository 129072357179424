import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryEmployerCountByDate from "./useQueryEmployerCountByDate.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import CountLineChart from "./CountLineChart.js";

export default function EmployerCountByDateItem(props) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "EmployerCountByDateItem");

  const { data, loading, errors } = useQueryEmployerCountByDate();

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="EmployerCountByDateItem">
            <PageSubtitle
              colTitle={t("admin:admin_dashboard_employers_count_by_date_title")}
            />
            <Row>
              <Col>
                <CountLineChart data={data }/>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
