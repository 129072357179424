import StringUtils from "../../utils/StringUtils.js";
import TypeUtils from "../../utils/TypeUtils.js";
import authorizationRules from "../../constants/authorizationRules.js";

/**
 * Takes a list of authorization rules (dependent on the application) and checks that a user with a given role can perform
 * a given action. If role is omitted, user has no permission whatsoever. If action is omitted, any authenticated user
 * has permission. If action is specified, user must be authenticated, must have a role and must obey the authorization rules.
 * @param rules Set of authorization rules
 * @param role User role.
 * @param action Action to check
 * @param data Additional data required to check the action, if any (such as ownership data)
 * @return {boolean|*} True if role can perform action
 */
function _check(rules, role, action, data) {

  // No role means no access
  if (StringUtils.isNullOrEmpty(role))
    return false;

  // Everyone with a role is authorized when there is no action
  if (StringUtils.isNullOrEmpty(action))
    return true;

  // Build the list of all roles inherited by current role, recursively
  const allApplicableRoles = [];
  _cumulateInheritedRoles(rules, allApplicableRoles, [role]);

  // Return true as soon as one static permission succeeds
  if (allApplicableRoles.some(role => rules[role].static && rules[role].static.includes(action)))
    return true;

  // Iterate over all dynamic permissions of all applicable roles and return true as soon as one succeeds
  // (there is no concept of duplicates to remove, dynamic permissions with same name but for different roles
  // may be defined differently)
  return (allApplicableRoles.some(role => rules[role].dynamic && rules[role].dynamic[action] && rules[role].dynamic[action](data)))
}

function _cumulateInheritedRoles(rules, roleAccumulator, newRoles) {

  // For each new role, check that it has not been processed already, to avoid cycles
  TypeUtils.ensureArray(newRoles).filter(role => !roleAccumulator.includes(role)).forEach(role => {
    // Then if role exists in rules, add it to the list of inherited roles, and process its inherits attribute
    if (rules[role]) {
      roleAccumulator.push(role);
      _cumulateInheritedRoles(rules, roleAccumulator, rules[role].inherits);
    }
  });
}

/**
 * Checks that a user has the permissions to perform an action.
 * @param authUser Authenticated user
 * @param action Action the user wants to perform
 * @param data Optional data when authorization check requires more info (such as ownership)
 * @return {boolean} True if authenticated user has permission to perform action
 */
export default function isUserAuthorized(authUser, action = undefined, data = undefined) {

  // An unauthenticated user is never authorized
  if (!authUser || !authUser.authenticated)
    return false;

  // If action is empty, an authenticated user is authorized
  if (StringUtils.isNullOrEmpty(action))
    return true;

  const role = authUser.profile ? authUser.profile.role : "";

  // Check permission
  return _check(authorizationRules, role, action, data);
}
