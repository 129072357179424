import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Table from "react-bootstrap/Table";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import TrueIcon from "@mui/icons-material/Check";
import TruncateText from "./TruncateText.js";
import useQueryApplicationAdminSearch from "../search/useQueryApplicationAdminSearch.js";
import DisplayDayIso from "./DisplayDayIso.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import useApplicationDelete from "../mutate/useApplicationDelete.js";

export default function ApplicationList({filter}) {
  const {
    data: applications,
    loading,
    errors,
  } = useQueryApplicationAdminSearch(
    1000,
    filter,
    "DATE_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "ApplicationList");

  const {FeedbackModal: DeleteFeedbackModal, loading: deleteLoading, onClick: onClickApplicationDelete} = useApplicationDelete(
    t("admin:admin_application_delete_confirm_msg"),
    t("admin:admin_application_delete_success_msg")
  );

  const columnsLabels = [
    t("admin:admin_application_date_column_label"),
    t("admin:admin_application_candidate_column_label"),
    t("admin:admin_application_company_column_label"),
    t("admin:admin_application_job_column_label"),
    t("admin:admin_application_recruiter_column_label"),
    t("admin:admin_application_recruiterNote_column_label"),
    t("admin:admin_application_status_column_label"),
    t("admin:admin_application_viewed_column_label"),
    t("admin:admin_application_candidateApprovalStatus_column_label"),
  ];

  return (
    <>
      {DeleteFeedbackModal}
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {applications["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td><DisplayDayIso date={item.updatedAt} /></td>
                      <td>{item.candidate && <TruncateText title={t("admin:admin_application_candidate_column_label")} text={item.candidate.name} length={30}/>}</td>
                      <td><TruncateText title={t("admin:admin_application_company_column_label")} text={item.job && item.job.companyName} length={10}/></td>
                      <td><TruncateText title={t("admin:admin_application_job_column_label")} text={item.job && item.job.title} length={15}/></td>
                      <td>{item.recruiter ? TypeUtils.arrayRemoveNullOrEmpty([item.recruiter.firstName, item.recruiter.lastName]).join(" ") : ""}</td>
                      <td><TruncateText title={t("admin:admin_application_recruiterNote_column_label")} text={item.recruiterNote} length={15}/></td>
                      <td><TruncateText title={t("admin:admin_application_status_column_label")} text={item.status} length={6}/></td>
                      <td>{item.viewed ? <TrueIcon/> : ""}</td>
                      <td><TruncateText title={t("admin:admin_application_candidateApprovalStatus_column_label")} text={item.candidateApprovalStatus} length={10}/></td>
                      <td><ActionLink loading={deleteLoading} onClick={() => onClickApplicationDelete(item._id)}>
                        {t("admin:admin_application_delete_label")}
                      </ActionLink></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
