import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationRecruiters from "../useTranslationRecruiters.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import useMutationRecruiterFeedbackUpdate from "./useMutationRecruiterFeedbackUpdate.js";
import useMutationRecruiterFeedbackDelete from "./useMutationRecruiterFeedbackDelete.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import StringUtils from "../../../utils/StringUtils.js";
import useFeedbackModal from "../../common/modals/useFeedbackModal.js";
import ReactStars from "react-rating-stars-component";
import ActionLink from "../../common/widgets/ActionLink.js";
import "./RecruiterFeedbackUpdateForm.scss";

export default function RecruiterFeedbackUpdateForm(props) {
  const { onClose, application, employerFeedback } = props;

  const { t, loading: tLoading } = useTranslationRecruiters();
  useShowLoader(tLoading);

  const {
    mutate,
    loading: mutationUpdateLoading,
    errors: mutationUpdateErrors,
  } = useMutationRecruiterFeedbackUpdate();

  const {
    mutate: deleteMutate,
    loading: mutationDeleteLoading,
    errors: mutationDeleteErrors,
  } = useMutationRecruiterFeedbackDelete();

  const errorUpdateMsg = useServerErrorFormatter(mutationUpdateErrors);
  const errorDeleteMsg = useServerErrorFormatter(mutationDeleteErrors);

  const { launch: launchUpdate, FeedbackModal: UpdateFeedbackModal } =
    useFeedbackModal({errorMsg: errorUpdateMsg});

  const { launch: launchDelete, FeedbackModal: DeleteFeedbackModal } =
    useFeedbackModal({errorMsg: errorDeleteMsg});

  const [feedback, setFeedback] = React.useState(employerFeedback.feedback);
  const [rating, setRating] = React.useState(employerFeedback.rating);

  const updateRecruiterFeedback = () => {
    return mutate({
      variables: {
        id: employerFeedback._id,
        feedback: feedback.trim(),
        rating: rating,
      },
    });
  };

  const deleteRecruiterFeedback = () => {
    return deleteMutate({
      variables: {
        id: employerFeedback._id,
      },
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    launchUpdate(updateRecruiterFeedback, onClose);
  };

  const onClickDeleteFeedback = (event) => {
    event.preventDefault();
    launchDelete(deleteRecruiterFeedback, onClose);
  };

  const canSubmit = () => {
    return !StringUtils.isNullOrEmpty(feedback) || rating > 0;
  };

  const ratingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <>
      {UpdateFeedbackModal}
      {DeleteFeedbackModal}
      <div className="RecruiterFeedbackUpdateForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <h1>
                {application.recruiter.firstName}{" "}
                {application.recruiter.lastName}
              </h1>
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>{t("recruiters:recruiters_add_rating_label")}</Col>
          </Row>
          <Row>
            <Col className={"rating-row"}>
              <ReactStars
                edit={true}
                count={5}
                value={rating}
                size={36}
                onChange={ratingChange}
              />
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>{t("recruiters:recruiters_add_feedback_label")}</Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                name="feedback"
                as="textarea"
                rows="3"
                value={feedback}
                onChange={(event) =>
                  setFeedback(StringUtils.superTrim(event.target.value))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ActionLink
                onClick={onClickDeleteFeedback}
                loading={mutationDeleteLoading}
              >
                {t("recruiters:recruiters_delete_feedback_label")}
              </ActionLink>
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButton
                loading={mutationUpdateLoading}
                type={"submit"}
                disabled={!canSubmit()}
              >
                {t("recruiters:recruiters_update_feedback_submit_action")}
              </ActionButton>
              <ActionButton onClick={onClose}>
                {t("recruiters:recruiters_update_feedback_cancel_action")}
              </ActionButton>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
