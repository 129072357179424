import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "employer";
const QUERY_NAME = "employerById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id
        firstName
        lastName
        phone
        email
        organization {
          _id
          company
          companyUrl
          companyPresentation
          companyIntroFr
          companyIntroEn
          companyPhone
          companyEmail
          companyFacebookUrl
          companyLinkedinUrl
          companyCoverTagLineFr
          companyCoverTagLineEn
          companyIsVerifiedAccount
          companyLogoUrl
          isGptEnabled
          jobDescriptionPromptEn: jobDescriptionPrompt(lang: EN)
          jobDescriptionPromptFr: jobDescriptionPrompt(lang: FR)
        }
      }
    }
  }
`;

export default function useQueryEmployerById(id) {

  const variables = { id };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, { variables });
}
