import React from "react";
import StringUtils from "../../../utils/StringUtils.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import "./NewApplicationByJobCount.scss";
import useQueryApplicationSearchCount from "./useQueryApplicationSearchCount.js";

function NewApplicationByJobCount(props) {
  const { jobId } = props;
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "NewApplicationsByJobCount");

  const {
    data: count,
    loading,
    errors,
  } = useQueryApplicationSearchCount(jobId, { viewed: false });

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() =>
          count > 0 && (
            <span className="NewApplicationByJobCount">
              {t("jobs:employers_job_details_new_candidate_count", {
                count: StringUtils.nullToZero(count),
              })}
            </span>
          )
        }
      />
    </HorizontalLoader>
  );
}

export default NewApplicationByJobCount;
