import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobById";

export const JOB_TYPE_ALL_FIELDS_FRAGMENT = gql`

  fragment JobTypeAllFieldsFragment on JobType {
    _id
    employerId
    title
    description
    streetAddress
    postalCode
    city
    state
    country
    datePosted
    dateUnpublished
    employmentType
    baseSalaryValue
    baseSalaryUnitText
    seniority
    commission
    status
    employerNote
    paymentStatus
    technicalSkills
    softSkills
    workMode
    isWorkPermitRequired
    lastSavedStep
    isCancelled
    isExternalHire
    hasBeenPublished
    jobDescriptionPrompt
    nlpSeniority
    nlpRequiredLanguages
    nlpRequiredTechnicalSkills
    nlpRequiredSoftSkills
    nlpResponsibilities
    nlpQualifications
    nlpRequiredDiploma
    nlpQuery
    nlpMatch
    organization {
      _id
      company
      companyUrl
      paymentTerms(lang: $lang)
      isSubscriptionActive
      isGptEnabled
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const QUERY = gql`
  ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!, $lang: GlobalLanguageEnum!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        ...JobTypeAllFieldsFragment
      }
    }
  }
`;

export default function useQueryJobById(id) {
  const { getCurrentLanguageCode } = useLanguages();
  const variables = { id, lang: getCurrentLanguageCode().toUpperCase() };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
    skip: id == null,
  });
}
