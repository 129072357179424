import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import { Form } from "react-bootstrap";
import "./JobSaveSalaryForm.scss";
import EmploymentTypeEnum, {getEmploymentTypeLabel} from "../EmploymentTypeEnum.js";

export default function JobSaveSalaryForm({t, loading, employmentType, onChange}) {
  useShowLoader(loading);

  return (
    <div className="JobSaveSalaryForm spaced-children widget-block">
      <Row>
        <Col className={"label-col"}>
          <div className="label-div">
            <p className="label">
              {t("jobs:employers_job_save_salary_employment_type_label")}
            </p>
            <Form.Select
              name={"employmentType"}
              onChange={onChange}
              value={employmentType}
              className={"employmentType-input"}
            >
              {Object.values(EmploymentTypeEnum).map((employmentType) => (
                <option key={employmentType} value={employmentType}>
                  {getEmploymentTypeLabel(t, employmentType)}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>
    </div>
  );
}
