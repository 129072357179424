import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";
import {JOB_TYPE_ALL_FIELDS_FRAGMENT} from "../view/useQueryJobById.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobCreate";

export const QUERY = gql`
    ${JOB_TYPE_ALL_FIELDS_FRAGMENT}
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
      $lang: GlobalLanguageEnum!
      $employerId: ID!,
      $title: String!, 
      $state: String,
      $country: String,
      $employmentType: JobEmploymentTypeEnum,
      $isWorkPermitRequired: Boolean,
      $workMode: JobWorkModeEnum
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          lang: $lang,
          employerId: $employerId,
          title: $title
          state: $state
          country: $country,
          employmentType: $employmentType,
          isWorkPermitRequired: $isWorkPermitRequired
          workMode: $workMode,
          lastSavedStep: TITLE
    ) {
          ...JobTypeAllFieldsFragment
        }
      }
    }
  `;

export default function useMutationJobCreate() {
  const {getCurrentLanguageCode} = useLanguages();
  const variables = {lang: getCurrentLanguageCode().toUpperCase()};

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {variables});
}
