import React, {useCallback} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Table from "react-bootstrap/Table";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import useQueryEmployerAdminSearch from "../search/useQueryEmployerAdminSearch.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import TrueIcon from "@mui/icons-material/Check";
import TruncateText from "./TruncateText.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import * as ROUTES from "../../../constants/routes.js";
import queryString from "query-string";
import DisplayDayIso from "./DisplayDayIso.js";
import JobByEmployerCountItem from "../search/JobByEmployerCountItem.js";
import PublishedJobByEmployerCountItem from "../search/PublishedJobByEmployerCountItem.js";
import JobStatusEnum from "../../jobs/JobStatusEnum.js";
import AdminTooltip from "./AdminTooltip.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function EmployerList(/*props*/) {
  const {
    data: employers,
    loading,
    errors,
  } = useQueryEmployerAdminSearch(
    1000,
    {},
    "CREATED_AT_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "EmployerList");

  const {redirectTo} = useReferrer();

  const goToAllJobs = useCallback(employerId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_JOBS,
      search: queryString.stringify({ employerId } ),
    });
  }, [redirectTo]);

  const goToPublishedJobs = useCallback(employerId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_JOBS,
      search: queryString.stringify({ employerId, status: JobStatusEnum.PUBLISHED } ),
    });
  }, [redirectTo]);

  const columnsLabels = [
    t("admin:admin_employer_date_column_label"),
    t("admin:admin_employer_name_column_label"),
    t("admin:admin_employer_phone_column_label"),
    t("admin:admin_employer_email_column_label"),
    t("admin:admin_employer_company_column_label"),
    t("admin:admin_employer_companyPresentation_column_label"),
    t("admin:admin_employer_active_column_label"),
    t("admin:admin_employer_job_count_column_label"),
    t("admin:admin_employer_publishedJob_count_column_label")
  ];

  return (
    <>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {employers["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th><AdminTooltip text={item._id} title={"ID"}>{i + 1}</AdminTooltip></th>
                      <td><DisplayDayIso date={item.createdAt} /></td>
                      <td>{TypeUtils.arrayRemoveNullOrEmpty([item.firstName, item.lastName]).join(" ")}</td>
                      <td>{item.phone}</td>
                      <td><TruncateText title={t("admin:admin_employer_email_column_label")} text={item.email} length={15}/></td>
                      <td><TruncateText title={t("admin:admin_employer_company_column_label")} text={item.organization.company} length={15}/></td>
                      <td><TruncateText title={t("admin:admin_employer_companyPresentation_column_label")} text={item.organization.companyPresentation} length={15}/></td>
                      <td>{item.isActiveAccount ? <TrueIcon/> : ""}</td>
                      <td><ActionLink onClick={() => goToAllJobs(item._id)}><JobByEmployerCountItem employerId={item._id}/></ActionLink></td>
                      <td><ActionLink onClick={() => goToPublishedJobs(item._id)}><PublishedJobByEmployerCountItem employerId={item._id}/></ActionLink></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
