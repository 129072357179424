import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import StringUtils from "../../../utils/StringUtils.js";
import WysiwygEditor from "./WysiwygEditor.js";

export default function WysiwygEditComponent(props) {
  const {
    editorRef,
    placeholder,
    value, // Editor state, not html string
    onChange,
    onBlur,
    validate,
  } = props;

  const [validationError, setValidationError] = React.useState();
  const hasError = !StringUtils.isNullOrEmpty(validationError);

  React.useEffect(() => {
    if (validate) setValidationError(validate(value));
  }, [value, validate]);

  return (
    <div className={"WysiwygEditComponent"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <WysiwygEditor
            editorRef={editorRef}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </div>
  );
}
