import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import JobDashboardAlertsBlock from "./JobDashboardAlertsBlock.js";
import JobDashboardApplicationsBlock from "./JobDashboardApplicationsBlock.js";
import JobDashboardSummaryBlock from "./JobDashboardSummaryBlock.js";
import JobDashboardCommissionBlock from "./JobDashboardCommissionBlock.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationJobDashboard from "../useTranslationJobDashboard.js";
import Alert from "react-bootstrap/Alert";
import JobStatistics from "./JobStatistics.js";
import "./JobDashboardBlock.scss";
import JobStatusEnum from "../../jobs/JobStatusEnum.js";

function JobDashboardBlock(props) {
  const { job } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "ApplicationPreview");

  const supportEmail =  process.env.REACT_APP_SUPPORT_EMAIL;
  const emailLinkComponent = <a href={"mailto:" + supportEmail}>{supportEmail}</a>
  const pendingAlertMsg = t("job-dashboard:job_dashboard_approval_alert_text", {
    postProcess: "reactPostprocessor",
    emailLink: emailLinkComponent
  });

  return (
    <div className="JobDashboardBlock">
      <Row>
        <Col>
          <JobDashboardSummaryBlock job={job} />
        </Col>
      </Row>
      {job.status === JobStatusEnum.PENDING && (
        <Row>
          <Col>
            <Alert variant={"danger"}>{pendingAlertMsg}</Alert>
          </Col>
        </Row>
      )}
      <Row className={"blockContainer"}>
        <Col className="blockContainerApplication">
          <div className="widget-block">
            <JobDashboardApplicationsBlock job={job} />
          </div>
        </Col>
        <Col className="blockContainerCommission ">
          <div className="widget-block">
            <JobDashboardCommissionBlock job={job} />
          </div>
        </Col>
        <Col className="blockContainerStatistics ">
          <div className="widget-block">
            <JobStatistics job={job} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <JobDashboardAlertsBlock job={job} />
        </Col>
      </Row>
    </div>
  );
}

export default JobDashboardBlock;
