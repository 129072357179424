import {useCallback, useMemo} from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useMutationJobPublish from "./useMutationJobPublish.js";
import JobSavePublicationForm from "./JobSavePublicationForm.js";

export default function useJobSavePublicationForm(job) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { mutate, loading: mutationLoading, errors } = useMutationJobPublish();

  const canSubmit = useCallback(() => {
    return true;
  }, []);

  const submit = useCallback(() => {
    return mutate({
      variables: {
        id: job._id,
      },
    });
  }, [mutate, job._id]);

  const formProps = useMemo(() => ({
    t,
    loading: tLoading,
    job
  }), [t, tLoading, job]);

  return {
    form: JobSavePublicationForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: mutationLoading,
    errors,
  };
}
