import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import "./RecruiterProfile.scss";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import useTranslationRecruiters from "../useTranslationRecruiters.js";
import Separator from "../../common/widgets/Separator.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import RecruiterFeedback from "./RecruiterFeedback.js";
import StringUtils from "../../../utils/StringUtils.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";

export default function RecruiterProfile(props) {
  const { application, onClose } = props;

  const recruiter = application.recruiter;

  const { t, loading: tLoading } = useTranslationRecruiters();
  useShowLoader(tLoading, "RecruiterProfile");

  const aboutMe = HtmlUtils.htmlToComponents(recruiter.aboutMe);

  return (
    <div className={"RecruiterProfile form-with-rows"}>
      <Row className={"first-row"}>
        <Col className="col-container">
          <Row className={"name-row"}>
            <Col className="name-col">
              <h1>
                {recruiter.firstName} {recruiter.lastName}
              </h1>
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>{t("recruiters:recruiters_contact_information_label")}</Col>
          </Row>
          <Row>
            <Col>
              <Row className={"contact-row"}>
                <Col className="icon-col">
                  <EmailIcon />
                </Col>
                <Col className={"contact-col"}>{recruiter.email}</Col>
              </Row>
              {!StringUtils.isNullOrEmpty(recruiter.phone) && (
                <Row className={"contact-row"}>
                  <Col className="icon-col">
                    <CallIcon />
                  </Col>
                  <Col className={"contact-col"}>{recruiter.phone}</Col>
                </Row>
              )}
            </Col>
          </Row>
          {!StringUtils.isNullOrEmpty(aboutMe) && (
            <>
              <Row className={"label-row"}>
                <Col>{t("recruiters:recruiters_about_me_label")}</Col>
              </Row>
              <Row>
                <Col>{aboutMe}</Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Separator />
      <Row>
        <Col>
          <RecruiterFeedback application={application} />
        </Col>
      </Row>
      <Row>
        <Col className="form-actions">
          <ActionButton onClick={onClose}>
            {t("recruiters:recruiters_close_action")}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
}
