import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryApplicationCount from "./useQueryApplicationCount.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./ApplicationCountItem.scss";

export default function ApplicationApprovalStatusCountItem(props) {
  const { approvalStatus, statusLabel } = props;

  const {
    data: count,
    loading,
    errors,
  } = useQueryApplicationCount({ candidateApprovalStatus: approvalStatus });

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="ApplicationApprovalStatusCountItem">
            <Row>
              <Col>
                <span>{statusLabel}</span>
              </Col>
              <Col>
                <span>{count}</span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
