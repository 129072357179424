import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import JobSearchBox from "../search/JobSearchBox.js";
import JobsList from "./JobsList.js";
import * as ROUTES from "../../../constants/routes.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import "./JobsBlock.scss";
import JobStatusEnum, {getJobStatusLabel} from "../JobStatusEnum.js";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import JobSortEnum from "../JobSortEnum.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import useQueryJobCountsByStatus from "../search/useQueryJobCountsByStatus.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import StringUtils from "../../../utils/StringUtils.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

function JobsBlock(props) {
  const { employerId } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobsBlock");

  const {data: counts, loading: countLoading, errors: countErrors} = useQueryJobCountsByStatus(employerId);

  // Transform list of counts into a map of counts
  const countsByStatus = React.useMemo(() => {
    const countMap = {};
    TypeUtils.ensureArray(counts).forEach(({status, count}) => countMap[status] = count);
    return countMap;
  },
  [counts]);

  const [currentSort, setCurrentSort] = React.useState(JobSortEnum.CREATED_AT_DESC);

  const {redirectTo} = useReferrer();

  const onClickCreate = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.EMPLOYER_JOB_CREATE);
  };

  // Generate tab list, made from statuses in a specific order and with their translated label
  const tabs = React.useMemo(() => {

    if (tLoading)
      return [];

    // Rejected jobs are not included so as not to clog the UI with negative features;
    // unsuitable jobs will remain PENDING until the employer modifies/deletes them.
    // Employers who do nothing to fix a job are unlikely to use the system in the future
    return [JobStatusEnum.PUBLISHED, JobStatusEnum.DRAFT, JobStatusEnum.PENDING, JobStatusEnum.UNPUBLISHED, JobStatusEnum.ARCHIVED].map(status => ({
      key: status,
      label: getJobStatusLabel(t, status)
    }));
  }, [t, tLoading]);

  // Generate list of sort options
  const sorts = React.useMemo(() => {

    if (tLoading)
      return [];

    return [{
      key: JobSortEnum.CREATED_AT_DESC,
      label: t("jobs:employers_all_jobs_created_at_desc_label")
    }, {
      key: JobSortEnum.CREATED_AT_ASC,
      label: t("jobs:employers_all_jobs_created_at_asc_label")
    }, {
      key: JobSortEnum.TITLE_ASC,
      label: t("jobs:employers_all_jobs_title_asc_label")
    }, {
      key: JobSortEnum.TITLE_DESC,
      label: t("jobs:employers_all_jobs_title_desc_label")
    }];
  }, [t, tLoading]);

  const onClickSort = (sort) => {
    // We can't use the dropdown onSelect() eventKey because it overrides the eventKey used by the enclosing Tab.Container
    setCurrentSort(sort);
  }

  return (
    <div className="JobsBlock">
      <Row className="title-row">
        <Col>
          <h1>{t("jobs:employers_all_jobs_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <ActionButton onClick={onClickCreate}>
            {t("jobs:employers_create_job_action")}
          </ActionButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <JobSearchBox
            autofocus
            employerId={employerId}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <WaitForData loading={countLoading} errors={countErrors} onLoaded={() =>
            <Tab.Container id="status-tab" defaultActiveKey={TypeUtils.arrayIsEmpty(tabs) ? "" : tabs[0].key}>
              <Nav variant={"tabs"} className={"list-header"}>
                {tabs.map(tab => {
                  // Do not display pending jobs if there are none (which will be the case for paying customers because
                  // their jobs are automatically approved, unless they had pending jobs when they started paying)
                  if (tab.key === JobStatusEnum.PENDING && !countsByStatus[tab.key])
                    return null;

                  return (
                    <Nav.Item key={tab.key}>
                      <Nav.Link eventKey={tab.key}>
                        {tab.label} ({StringUtils.nullToZero(countsByStatus[tab.key])})
                      </Nav.Link>
                    </Nav.Item>
                  )
                })}
                <Dropdown className={"first-icon"}>
                  <Dropdown.Toggle as={"a"} className={"sort-toggle"}>
                    {t("jobs:employers_all_jobs_sort_label")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {sorts.map(sort =>
                      <Dropdown.Item key={sort.key} onSelect={() => onClickSort(sort.key)}
                                     active={sort.key === currentSort}>{sort.label}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
              <Tab.Content>
                {tabs.map(tab =>
                  <Tab.Pane key={tab.key} eventKey={tab.key}>
                    <Row>
                      <Col>
                        <JobsList
                          employerId={employerId}
                          status={tab.key}
                          sort={currentSort}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          }/>
        </Col>
      </Row>
    </div>
  );
}

export default JobsBlock;
