import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./JobSaveDescriptionForm.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ValidatedInputWysiwyg from "../../common/widgets/ValidatedInputWysiwyg.js";
import {formatWysiwygInput} from "../../common/widgets/WysiwygEditor.js";
import {MAX_LENGTH_JOB_DESCRIPTION} from "../../../constants/ui.js";
import WysiwygCounterDisplay from "../../common/widgets/WysiwygCounterDisplay.js";
import useContentModal from "../../common/modals/useContentModal.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import DotsIcon from "../../../img/horizontal-dots-small.gif";
import Image from "react-bootstrap/Image";
import ActionLink from "../../common/widgets/ActionLink.js";

export default function JobSaveDescriptionForm({t, control, job, loading, submitting, textLength, onLaunchGenerate, generating}) {
  useShowLoader(loading);

  // Modal while waiting for NLP analysis
  const {
    ContentModal: WaitingModal,
    show: showWaitingModal,
    hide: hideWaitingModal,
  } = useContentModal("WaitingModal");

  // Display a modal when waiting for the mutation to finish
  React.useEffect(() => {
    if (submitting) {
      showWaitingModal(
        <div className={"JobSaveDescriptionWaitingModal"}>
          <div>
            {HtmlUtils.htmlToComponents(t("jobs:employers_job_save_description_wait_msg"))}
          </div>
          <div className={"loading-icon"}>
            <Image src={DotsIcon}/>
          </div>
        </div>
      );
    } else {
      hideWaitingModal();
    }
  }, [submitting, showWaitingModal, hideWaitingModal, t]);

  // Modal while waiting for description generation
  const {
    ContentModal: GeneratingModal,
    show: showGeneratingModal,
    hide: hideGeneratingModal,
  } = useContentModal("GeneratingModal");

  // Display a modal when waiting for the generation to finish
  React.useEffect(() => {
    if (generating) {
      showGeneratingModal(
        <div className={"JobSaveDescriptionGeneratingModal"}>
          <div>
            {HtmlUtils.htmlToComponents(t("jobs:employers_job_save_description_generating_msg"))}
          </div>
          <div className={"loading-icon"}>
            <Image src={DotsIcon}/>
          </div>
        </div>
      );
    } else {
      hideGeneratingModal();
    }
  }, [generating, showGeneratingModal, hideGeneratingModal, t]);

  return (
    <>
      {WaitingModal}
      {GeneratingModal}
      <div className="JobSaveDescriptionForm">
        <Row>
          <Col className="job-description-invite">
            {t("jobs:employers_job_save_description_invite_msg")}
          </Col>
        </Row>
        {job.organization.isGptEnabled &&
        <Row>
          <Col className={"job-description-generate"}>
            <ActionLink onClick={() => onLaunchGenerate()}>{t("jobs:employers_job_save_description_generate_action")}</ActionLink>
          </Col>
        </Row>
        }
        <Row>
          <Col>
            <ValidatedInputWysiwyg
              name={"description"}
              defaultValue={formatWysiwygInput(job.description)}
              control={control}
              maxLength={MAX_LENGTH_JOB_DESCRIPTION}
              required
            />
            <WysiwygCounterDisplay
              maxLength={MAX_LENGTH_JOB_DESCRIPTION}
              length={textLength}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}
