import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionDiv from "../../common/widgets/ActionDiv.js";
import "./ApplicationPreview.scss";
import useContentModal from "../../common/modals/useContentModal.js";
import ApplicationUpdateForm from "../../candidates/mutate/ApplicationUpdateForm.js";
import RecruiterProfile from "../../recruiters/view/RecruiterProfile.js";
import PersonIcon from "@mui/icons-material/Person";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationJobDashboard from "../useTranslationJobDashboard.js";
import useMutationCandidateUpdate from "../../candidates/mutate/useMutationApplicationUpdate.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import ResumeDownloadIcon from "../../candidates/view/ResumeDownloadIcon.js";

export default function ApplicationPreview(props) {
  const { application } = props;

  const { t, loading: tLoading } = useTranslationJobDashboard();
  useShowLoader(tLoading, "ApplicationPreview");

  // Modal to show application details form
  const {
    ContentModal: ApplicationUpdateModal,
    show: showApplicationUpdateModal,
    hide: hideApplicationUpdateModal,
  } = useContentModal("ApplicationUpdateModal");

  // Modal to show recruiter details form
  const {
    ContentModal: RecruiterUpdateModal,
    show: showRecruiterUpdateModal,
    hide: hideRecruiterUpdateModal,
  } = useContentModal("RecruiterUpdateModal");

  const { mutate, errors } = useMutationCandidateUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("job-dashboard:job_dashboard_details_update_error")}</p>
      {errorMsg}
    </>
  );
  const onClickApplication = () => {
    if (!application.viewed) {
      const variables = {};
      variables["id"] = application._id;
      variables["viewed"] = true;
      mutate({ variables }).catch(() => {
        showErrorModal();
      });
    }
    showApplicationUpdateModal(getApplicationUpdateForm());
  };

  const onClickRecruiter = () => {
    showRecruiterUpdateModal(getRecruiterProfile());
  };

  const getApplicationUpdateForm = () => {
    return (
      <ApplicationUpdateForm
        onClose={hideApplicationUpdateModal}
        applicationId={application._id}
      />
    );
  };

  const getRecruiterProfile = () => {
    return (
      <RecruiterProfile
        onClose={hideRecruiterUpdateModal}
        application={application}
      />
    );
  };

  return (
    <>
      {application && (
        <>
          {ApplicationUpdateModal}
          {RecruiterUpdateModal}
          {ErrorModal}
          <div className="ApplicationPreview">
            <Row className={"name-row"}>
              <Col className="candidate-name-col">
                <ActionDiv
                  className="candidate-name"
                  onClick={() => onClickApplication()}
                >
                  {application.candidate.name}
                </ActionDiv>
              </Col>
              {application.recruiter.isActiveAccount && <Col className={"referrer-col"}>
                {t("job-dashboard:job_dashboard_referrer_label")}
                <ActionDiv
                  className="ref-name"
                  onClick={() => onClickRecruiter()}
                >
                  {application.recruiter.firstName}{" "}
                  {application.recruiter.lastName}
                </ActionDiv>
              </Col>}
              <Col className="action-icon">
                <ActionDiv onClick={() => onClickApplication()}>
                  <PersonIcon />
                </ActionDiv>
              </Col>
              {application.candidate.resumeId && (
                <Col className="action-icon">
                  <ResumeDownloadIcon
                    resumeId={application.resumeId}
                    showLabel={false}
                  />
                </Col>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  );
}
