import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "applicationAdmin";
const QUERY_NAME = "applicationDateGroupAndCount";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME} {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} {
        date
        count
      }
    }
  }
`;

export default function useQueryApplicationCountByDate() {

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
