import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import StringUtils from "../../../utils/StringUtils.js";
import useMutationApplicationUpdate from "./useMutationApplicationUpdate.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import useTranslationCandidates from "../useTranslationCandidates.js";

/**
 * Generic component for all application editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ApplicationInlineEditInput(props) {
  const {
    InlineEditInputComponent,
    application,
    viewAs,
    validate,
    placeholder,
    property,
    className,
    index,
    openEditing,
    ViewComponent,
    maxLength
  } = props;
  const { mutate, loading, errors } = useMutationApplicationUpdate();
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  // Get the current value for the property. If an index is provided, it means the property refers to an array of values
  const currentValue =
    index == null ? application[property] : application[property][index];

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = application._id;

    // If an index is provided, the property is an array of values and we are modifying only one item
    if (index != null) {
      // First make a copy of the array before modifying it
      variables[property] = TypeUtils.shallowCopyArray(application[property]);
      variables[property][index] = value;

      // Remove all empty values
      variables[property] = TypeUtils.arrayRemoveNullOrEmpty(variables[property]);
    } else {
      // The property is a single value, replace it
      variables[property] = value;
    }

    mutate({ variables }).catch((error) => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <div className={["ApplicationInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(currentValue)}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          openEditing={openEditing}
          ViewComponent={ViewComponent}
          maxLength={maxLength}
        />
      </div>
    </>
  );
}
