import React from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionLink from "../../common/widgets/ActionLink.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import StringUtils from "../../../utils/StringUtils.js";
import useJobSaveTitleForm from "./useJobSaveTitleForm.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import "./JobSaveForm.scss";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import JobStatusEnum from "../JobStatusEnum.js";
import useJobSaveDescriptionForm from "./useJobSaveDescriptionForm.js";
import useJobSaveSkillsForm from "./useJobSaveSkillsForm.js";
import useJobSaveLocationForm from "./useJobSaveLocationForm.js";
import useJobSaveSalaryForm from "./useJobSaveSalaryForm.js";
import useJobSaveCommissionForm from "./useJobSaveCommissionForm.js";
import useJobSavePublicationForm from "./useJobSavePublicationForm.js";

export const STEP_ENUM = {
  TITLE: "TITLE",
  DESCRIPTION: "DESCRIPTION",
  LOCATION: "LOCATION",
  SKILLS: "SKILLS",
  SALARY: "SALARY",
  COMMISSION: "COMMISSION",
  PUBLICATION: "PUBLICATION",
};

export default function JobSaveForm({ job, step, onChangeStep, onCompleted }) {

  const { t, loading } = useTranslationJobs();
  useShowLoader(loading);

  const stepOrder = React.useMemo(() => ({
    [STEP_ENUM.TITLE]: true,
    [STEP_ENUM.DESCRIPTION]: true,
    [STEP_ENUM.SKILLS]: true,
    [STEP_ENUM.LOCATION]: true,
    [STEP_ENUM.SALARY]: true,
    [STEP_ENUM.COMMISSION]: true,
    [STEP_ENUM.PUBLICATION]: job.status !== JobStatusEnum.PUBLISHED && job.status !== JobStatusEnum.PENDING
    }), [job.status]
  );

  const visibleStepNames = React.useMemo(() =>
    Object.keys(stepOrder).filter(stepName => stepOrder[stepName]),
    [stepOrder]);

  // Find the visible step by name and return its index. If step is not visible, go backward to find the closest visible one.
  const getClosestVisibleStepIndexByName = React.useCallback(name => {
    // Move backward from position (including current) until step is visible
    let index = Object.keys(stepOrder).indexOf(name);

    while (index > -1 && !Object.values(stepOrder)[index]) {
      index--;
    }

    const closestVisibleName = Object.keys(stepOrder)[index];
    return visibleStepNames.indexOf(closestVisibleName);
  }, [stepOrder, visibleStepNames]);

  // Find last saved step in visibleStepNames array. If step is empty / not found, use -1.
  const lastSavedStepIndex = getClosestVisibleStepIndexByName(job.lastSavedStep);

  // Find last visited step in visibleStepNames array. If step is empty / not found, use -1.
  const lastVisitedStepIndex = Math.min(lastSavedStepIndex + 1, visibleStepNames.length -1);

  // Find target step index from step name provided in props. If step is not visible, go backward to find the closest
  // visible step. If none found, use step 0.
  // If step is after the last visited step, use the last visited step.
  const currentStepIndex = React.useMemo(() => Math.max(
    0,
    Math.min(getClosestVisibleStepIndexByName(step), lastVisitedStepIndex)
  ), [getClosestVisibleStepIndexByName, step, lastVisitedStepIndex]);

  const isStepSaved = React.useCallback(stepName =>
    Object.keys(stepOrder).indexOf(stepName) <= Object.keys(stepOrder).indexOf(job.lastSavedStep),
    [stepOrder, job.lastSavedStep]);

  const jobSaveTitleForm = useJobSaveTitleForm(job, isStepSaved(STEP_ENUM.TITLE));
  const jobSaveDescriptionForm = useJobSaveDescriptionForm(job, isStepSaved(STEP_ENUM.DESCRIPTION));
  const jobSaveSkillsForm = useJobSaveSkillsForm(job, isStepSaved(STEP_ENUM.SKILLS));
  const jobSaveLocationForm = useJobSaveLocationForm(job, isStepSaved(STEP_ENUM.LOCATION));
  const jobSaveSalaryForm = useJobSaveSalaryForm(job, isStepSaved(STEP_ENUM.SALARY));
  const jobSaveCommissionForm = useJobSaveCommissionForm(job, isStepSaved(STEP_ENUM.COMMISSION));
  const jobSavePublicationForm = useJobSavePublicationForm(job);

  // Define all the steps of the form
  const stepDefinitions = React.useMemo(() => ({
    [STEP_ENUM.TITLE]: {
      label: t("jobs:employers_job_save_title_step_label"),
      ...jobSaveTitleForm,
      className: "title-step-container",
    },
    [STEP_ENUM.DESCRIPTION]: {
      label: t("jobs:employers_job_save_description_step_label"),
      ...jobSaveDescriptionForm,
    },
    [STEP_ENUM.SKILLS]: {
      label: t("jobs:employers_job_save_skills_step_label"),
      ...jobSaveSkillsForm,
    },
    [STEP_ENUM.LOCATION]: {
      label: t("jobs:employers_job_save_location_step_label"),
      ...jobSaveLocationForm,
      className: "location-step-container",
    },
    [STEP_ENUM.SALARY]: {
      label: t("jobs:employers_job_save_employment_step_label"),
      ...jobSaveSalaryForm,
      className: "salary-step-container",
    },
    [STEP_ENUM.COMMISSION]: {
      label: t("jobs:employers_job_save_commission_step_label"),
      ...jobSaveCommissionForm
    },
    [STEP_ENUM.PUBLICATION]: {
      label: t("jobs:employers_job_save_publication_step_label"),
      ...jobSavePublicationForm
    }
  }), [t, jobSaveTitleForm, jobSaveDescriptionForm, jobSaveSkillsForm, jobSaveLocationForm, jobSaveSalaryForm, jobSaveCommissionForm, jobSavePublicationForm]);

  const currentStepName = visibleStepNames[currentStepIndex];
  const currentStepDefinition = stepDefinitions[currentStepName];
  const CurrentForm = currentStepDefinition.form;

  // Form is not ready for display until the form content is ready
  const ready = currentStepDefinition.ready;

  // It is an error to display a step other than the first one if there is no existing job
  const jobNotFound = React.useMemo(() =>
    currentStepIndex !== 0 && !job._id ? (<p>{t("jobs:employers_job_save_no_job")}</p>) : null,
    [currentStepIndex, job._id, t]
  );
  const submitError = useServerErrorFormatter(currentStepDefinition.errors);
  const error = jobNotFound || submitError;
  const { ErrorModal, show: showErrorModal } = useErrorModal(error);

  // Get name of previous visible step (excluding current position)
  const prevStepName = currentStepIndex < 0 ? "" : visibleStepNames[currentStepIndex - 1];

  // Get name of next visible step (excluding current position)
  const nextStepName = currentStepIndex > visibleStepNames.length - 2 ? "" : visibleStepNames[currentStepIndex + 1];

  const onClickPrevStep = React.useCallback((event) => {
    event.preventDefault();
    onChangeStep(prevStepName);
  }, [onChangeStep, prevStepName]);

  const onClickNextStep = React.useCallback((event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then((job) => onChangeStep(nextStepName, job._id))
      .catch((/*error*/) => {
        showErrorModal();
      });
  }, [currentStepDefinition, onChangeStep, nextStepName, showErrorModal]);

  const onClickLastStep = React.useCallback((event) => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then(() => {
        if (onCompleted) return onCompleted();
      })
      .catch((/*error*/) => {
        showErrorModal();
      });
  }, [currentStepDefinition, onCompleted, showErrorModal]);

  // Label of the last action
  const saveLastLabel = job.status === JobStatusEnum.PUBLISHED
    ? t("jobs:employers_job_update_last_action")
    : t("jobs:employers_job_save_last_action");

  const submitLabel = StringUtils.isNullOrEmpty(nextStepName) ? saveLastLabel : t("jobs:employers_job_save_next_action");

  const onSubmit = StringUtils.isNullOrEmpty(nextStepName) ? onClickLastStep : onClickNextStep;

  return (
    <>
      {ErrorModal}
      <form onSubmit={ (event) => onSubmit(event)} className="JobSaveForm form-with-rows">
        <Row>
          <div className="step-div">
            <Col className="step-wizard">
              <ul className={"step-wizard-list"}>
                {visibleStepNames.map((stepName, stepIndex) => (
                  <li key={stepName} className={"step-wizard-item"}>
                    <StepIndicator
                      stepName={stepName}
                      stepIndex={stepIndex}
                      stepDefinition={stepDefinitions[stepName]}
                      isCurrent={stepIndex === currentStepIndex}
                      isSaved={stepIndex <= lastSavedStepIndex}
                      isVisited={stepIndex <= lastSavedStepIndex + 1}
                      onChangeStep={onChangeStep}
                    />
                  </li>
                ))}
              </ul>
            </Col>
          </div>
        </Row>
        <Row className="form-row">
          <Col className={StringUtils.nullToEmpty(currentStepDefinition.className)}>
            <CurrentForm {...currentStepDefinition.formProps} loading={!ready}/>
          </Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            {!StringUtils.isNullOrEmpty(prevStepName) && (
              <ActionButton onClick={onClickPrevStep}>
                {t("jobs:employers_job_save_previous_action")}
              </ActionButton>
            )}
            <ActionButton
              type="submit"
              loading={currentStepDefinition.submitting}
              disabled={!currentStepDefinition.canSubmit() || !ready}
            >
              {submitLabel}
            </ActionButton>
          </Col>
        </Row>
      </form>
    </>
  );
}

// Make a link to reach a step directly without using Previous and Next buttons
function StepIndicator({stepName, stepIndex, stepDefinition, isCurrent, isSaved, isVisited, onChangeStep}) {

  const stepLabel = stepDefinition.label;
  const progressCount = isSaved ? <span className="progress-count"/> : <span className="progress-count-number">{stepIndex + 1}</span>;

  // Tell the parent that the step has changed, in case it wants to change the url
  const onClickStep = React.useCallback((event, stepName) => {
    event.preventDefault();
    onChangeStep(stepName);
  }, [onChangeStep]);

  if (isCurrent) {
    // Current step is highlighted and not clickable
    return (
      <>
        {progressCount}
        <span className={"current-step-link progress-label"}>
            {stepLabel}
          </span>
      </>
    );
  } else if (!isVisited) {
    // The steps not visited yet do not have a link
    return (
      <>
        {progressCount}
        <span className="progress-label">{stepLabel}</span>
      </>
    );
  } else {
    // Steps previously visited have a link
    return (
      <>
        {progressCount}
        <span className="progress-span">
            <ActionLink
              className="progress-action"
              onClick={(event) => onClickStep(event, stepName)}
            >
              {stepLabel}
            </ActionLink>
          </span>
      </>
    );
  }
}

