import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id
        candidateId
        candidate {
          _id
          name
          phone
          email
          linkedin
          indeed
          resumeId
        }
        recruiterId
        recruiter {
          _id
          firstName
          lastName
          phone
          email
          aboutMe
          isActiveAccount
        }
        resumeId
        recruiterNote
        employerNote
        status
        createdAt
      }
    }
  }
`;

export default function useQueryApplicationById(id) {
  const variables = { id };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
