import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import RefreshButton from "../../common/widgets/RefreshButton.js";
import "./ApplicationListBlock.scss";
import ApplicationList from "./ApplicationList.js";

function ApplicationListBlock({filter}) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationListBlock");

  return (
    <div className="ApplicationListBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_application_list_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationList filter={filter}/>
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationListBlock;
