import React, {useCallback} from "react";
import Popover from "react-bootstrap/Popover";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function AdminTooltip({title, text, children}) {

  const popover = useCallback((title, html) => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>{HtmlUtils.htmlToComponents(html)}</Popover.Content>
    </Popover>
  ), []);

  return <OverlayTrigger overlay={popover(title, text)}><span className={"clickable-text"}>{children}</span></OverlayTrigger>;
};
