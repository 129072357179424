import React from "react";
import {Navigate} from "react-router-dom";
import * as ROUTES from "../../constants/routes.js";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import useSignOut from "../authentication-no-ui/useSignOut.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";

export default function SignOutPage() {

  const authUser = useAuthUser();
  const {signOut, loading, ready: signOutReady} = useSignOut();
  useShowLoader(loading || !signOutReady, "SignOutPage");

  // After mount, perform sign out
  React.useEffect(() => {
    signOut();

    // Make sure signOut is not performed again when unmounting
    return () => {};
  }, [signOut /*avoid warning*/]);

  // RENDER

  // If user is not signed in, redirect to Home page;
  // Do not use <WithRedirectionUserIsAuthorized> component here because we don't want to redirect to sign in with this page as a referrer
  if (!authUser.authenticated) {
    return <Navigate replace to={ROUTES.SIGN_IN}/>;
  }

  // Nothing to display, the useSignOut will take care of displaying a loader
  return null;
}
