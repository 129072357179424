import React from "react";
import Page from "../../common/page/Page.js";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import EmployerListBlock from "./EmployerListBlock.js";


export default function EmployerListPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "EmployerListPage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="EmployerListPage">
          <Page.Title
            text={t("admin:admin_employer_list_title")}
            loading={tLoading}
          />
          <Page.Content>
            <EmployerListBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
