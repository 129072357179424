import React from "react";
import Page from "../../common/page/Page.js";
import useTranslationJobs from "../useTranslationJobs.js";
import JobsBlock from "./JobsBlock.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";

export default function JobsPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationJobs();
  const { profile } = useAuthUser();

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="JobsPage">
          <Page.Title
            loading={tLoading}
            text={t("jobs:employers_all_jobs_title")}
          />
          <Page.Content>
            <JobsBlock employerId={profile._id} />
          </Page.Content>
        </Page>
      )}
    />
  );
}
