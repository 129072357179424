import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";
import { QUERY as QUERY_RECRUITER_FEEDBACK } from "../view/useQueryRecruiterFeedbackByRecruiterId.js";

const QUERY_SCOPE = "recruiterFeedback";
const QUERY_NAME = "recruiterFeedbackCreate";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $recruiterId: ID!,
    $employerId: ID!,
    $feedback: String,
    $rating: Int
  ) {
    ${QUERY_SCOPE} { 
      _id
      ${QUERY_NAME} (
        recruiterId: $recruiterId,
        employerId:  $employerId,
        feedback: $feedback,
        rating: $rating
        ) {
          _id
        }
      }
    }
  `;

export default function useMutationRecruiterFeedbackCreate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_RECRUITER_FEEDBACK],
  });
}
