import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ApplicationByJobCountList from "./ApplicationByJobCountList.js";
import RefreshButton from "../../common/widgets/RefreshButton.js";
import "./ApplicationCountByJobBlock.scss";

function ApplicationCountByJobBlock() {
  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading, "ApplicationCountByJobBlock");

  return (
    <div className="ApplicationCountByJobBlock">
      <Row>
        <Col>
          <h1>{t("admin:admin_application_count_by_job_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <RefreshButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <ApplicationByJobCountList />
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationCountByJobBlock;
