import React, {useCallback} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Table from "react-bootstrap/Table";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import useQueryRecruiterAdminSearch from "../search/useQueryRecruiterAdminSearch.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import TrueIcon from "@mui/icons-material/Check";
import ApplicationByRecruiterCountItem from "../search/ApplicationByRecruiterCountItem.js";
import TruncateText from "./TruncateText.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import * as ROUTES from "../../../constants/routes.js";
import queryString from "query-string";
import DisplayDayIso from "./DisplayDayIso.js";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";
import ApprovedApplicationByRecruiterCountItem from "../search/ApprovedApplicationByRecruiterCountItem.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function RecruiterList(/*props*/) {
  const {
    data: recruiters,
    loading,
    errors,
  } = useQueryRecruiterAdminSearch(
    1000,
    {},
    "CREATED_AT_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "RecruiterList");

  const {redirectTo} = useReferrer();

  const goToAllApplications = useCallback(recruiterId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_APPLICATIONS,
      search: queryString.stringify({ recruiterId } ),
    });
  }, [redirectTo]);

  const goToApprovedApplications = useCallback(recruiterId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_APPLICATIONS,
      search: queryString.stringify({ recruiterId, candidateApprovalStatus: CandidateApprovalStatusEnum.APPROVED } ),
    });
  }, [redirectTo]);

  const columnsLabels = [
    t("admin:admin_recruiter_date_column_label"),
    t("admin:admin_recruiter_name_column_label"),
    t("admin:admin_recruiter_phone_column_label"),
    t("admin:admin_recruiter_email_column_label"),
    t("admin:admin_recruiter_jobTitle_column_label"),
    t("admin:admin_recruiter_location_column_label"),
    t("admin:admin_recruiter_about_column_label"),
    t("admin:admin_recruiter_active_column_label"),
    t("admin:admin_recruiter_application_count_column_label"),
    t("admin:admin_recruiter_approvedApplication_count_column_label")
  ];

  return (
    <>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {recruiters["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td><DisplayDayIso date={item.createdAt} /></td>
                      <td>{TypeUtils.arrayRemoveNullOrEmpty([item.firstName, item.lastName]).join(" ")}</td>
                      <td>{item.phone}</td>
                      <td><TruncateText title={t("admin:admin_recruiter_email_column_label")} text={item.email} length={15}/></td>
                      <td><TruncateText title={t("admin:admin_recruiter_jobTitle_column_label")} text={item.jobTitle} length={15}/></td>
                      <td>{TypeUtils.arrayRemoveNullOrEmpty([item.city, item.country]).join(", ")}</td>
                      <td><TruncateText title={t("admin:admin_recruiter_about_column_label")} text={item.aboutMe} length={15}/></td>
                      <td>{item.isActiveAccount ? <TrueIcon/> : ""}</td>
                      <td><ActionLink onClick={() => goToAllApplications(item._id)}><ApplicationByRecruiterCountItem recruiterId={item._id}/></ActionLink></td>
                      <td><ActionLink onClick={() => goToApprovedApplications(item._id)}><ApprovedApplicationByRecruiterCountItem recruiterId={item._id}/></ActionLink></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
