import React, {useCallback} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ActionDiv from "../../common/widgets/ActionDiv.js";
import "./CandidateApplicationPreview.scss";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import StringUtils from "../../../utils/StringUtils.js";
import useContentModal from "../../common/modals/useContentModal.js";
import ApplicationUpdateForm from "../mutate/ApplicationUpdateForm.js";
import RecruiterProfile from "../../recruiters/view/RecruiterProfile.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import ThreeStateCheckbox from "../../common/components/ThreeStateCheckbox.js";
import useMutationApplicationUpdate from "../mutate/useMutationApplicationUpdate.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";
import useApplicationEffectiveStatusDate from "./useApplicationEffectiveStatusDate.js";

const NOTE_LENGTH = 15;

export default function CandidateApplicationPreview({ application, selected, handleApplicationSelect, onChangeStatus }) {
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateApplicationPreview");

  const fullNote = HtmlUtils.removeHtml(application.recruiterNote).trim();

  // Modal to show application details form
  const {
    ContentModal: ApplicationUpdateModal,
    show: showApplicationUpdateModal,
    hide: hideApplicationUpdateModal,
  } = useContentModal("ApplicationUpdateModal");

  // Modal to show recruiter details form
  const {
    ContentModal: RecruiterUpdateModal,
    show: showRecruiterUpdateModal,
    hide: hideRecruiterUpdateModal,
  } = useContentModal("RecruiterUpdateModal");

  //si la note est plus grande que NOTE_LENGTH la couper et rajouter des points de suspension
  const truncatedNote =
    fullNote.length > NOTE_LENGTH
      ? fullNote.substring(0, NOTE_LENGTH).trim().concat("\u2026")
      : StringUtils.nullToEmpty(fullNote);

  // ajouter des guillemets si la note n'est pas vide
  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  const { mutate, errors } = useMutationApplicationUpdate();
  const errorMsg = useServerErrorFormatter(errors);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_details_update_error")}</p>
      {errorMsg}
    </>
  );
  const onClickApplication = () => {
    if (!application.viewed) {
      const variables = {};
      variables["id"] = application._id;
      variables["viewed"] = true;
      mutate({ variables }).catch(() => {
        showErrorModal();
      });
    }
    showApplicationUpdateModal(getApplicationUpdateForm());
  };

  const onClickRecruiter = () => {
    showRecruiterUpdateModal(getRecruiterProfile());
  };

  const getApplicationUpdateForm = () => {
    return (
      <ApplicationUpdateForm
        onClose={hideApplicationUpdateModal}
        applicationId={application._id}
        onChangeStatus={onChangeStatus}
      />
    );
  };

  const getRecruiterProfile = () => {
    return (
      <RecruiterProfile
        onClose={hideRecruiterUpdateModal}
        application={application}
      />
    );
  };

  const statusNewLabel = t(
    "candidates:candidates_application_status_new_label"
  );

  const getStatusLabel = useCallback(status => {
    if (tLoading)
      return "";
    switch(status) {
      case ApplicationStatusEnum.STACK:
        return t("candidates:candidates_send_to_stack_label");
      case ApplicationStatusEnum.REJECTED:
        return t("candidates:candidates_send_to_rejected_label");
      case ApplicationStatusEnum.SHORTLIST:
        return t("candidates:candidates_send_to_shortlist_label");
      case ApplicationStatusEnum.HIRED:
        return t("candidates:candidates_send_to_hired_label");
      case ApplicationStatusEnum.HIRING_CANCELLED:
        return t("candidates:candidates_send_to_hiring_cancelled_label");
      default:
        return "";
    }
  }, [t, tLoading]); // depend on tLoading because t doesn't change when t is ready

  const effectiveDate = useApplicationEffectiveStatusDate(application);

  return (
    <>
      {ApplicationUpdateModal}
      {RecruiterUpdateModal}
      {ErrorModal}
      <div className="CandidateApplicationPreview">
        <Row className={"name-row"}>
          <Col className="Checkbox-col">
            <ThreeStateCheckbox
              checked={selected}
              onChange={handleApplicationSelect}
              name={application._id}
            />
          </Col>
          <Col className={"identifier-col"}>
            <Row>
              <Col className="action-container">
                <Row>
                  <Col className="action-col">
                    <ActionDiv
                      className="link"
                      onClick={() => onClickApplication()}
                    >
                      {application.candidate.name}
                    </ActionDiv>
                  </Col>
                </Row>
                <Row>
                  <Col className={"note-col"}>
                    <p className="noteParagraph">{note}</p>
                  </Col>
                </Row>
              </Col>
              <Col className="new-badge-col">
                {!application.viewed && (
                  <Badge className="new-badge">{statusNewLabel}</Badge>
                )}
              </Col>
            </Row>
          </Col>
          {application.recruiter.isActiveAccount && (
            <Col className={"referrer-col"}>
              {t("candidates:candidates_referrer_label")}
              <ActionDiv
                className="link"
                onClick={() => onClickRecruiter()}
              >
                {application.recruiter.firstName + "\u00a0"}
                {application.recruiter.lastName}
              </ActionDiv>
            </Col>
          )}
          <Col className="statusCol">
            <Row>
              <Col>
                <span className={"statusPill"}>{getStatusLabel(application.status)}</span>
              </Col>
            </Row>
            {effectiveDate &&
            <Row>
              <Col className={"effectiveCol"}>
                {t("candidates:candidates_details_effective_label")} {effectiveDate}
              </Col>
            </Row>
            }
          </Col>
        </Row>
      </div>
    </>
  );
}
