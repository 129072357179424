import React, {useCallback} from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAdmin from "../useTranslationAdmin.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import Table from "react-bootstrap/Table";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import TruncateText from "./TruncateText.js";
import DisplayDayIso from "./DisplayDayIso.js";
import useQueryJobAdminSearch from "../search/useQueryJobAdminSearch.js";
import ApprovedApplicationByJobCountItem from "../search/ApprovedApplicationByJobCountItem.js";
import * as ROUTES from "../../../constants/routes.js";
import queryString from "query-string";
import ActionLink from "../../common/widgets/ActionLink.js";
import ApplicationByJobCountItem from "../search/ApplicationByJobCountItem.js";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function DetailedJobList({filter}) {
  const {
    data: jobs,
    loading,
    errors,
  } = useQueryJobAdminSearch(
    1000,
    filter,
    "CREATED_AT_DESC"
  );

  const { t, loading: tLoading } = useTranslationAdmin();
  useShowLoader(tLoading || loading, "DetailedJobList");

  const {redirectTo} = useReferrer();

  const goToAllApplications = useCallback(jobId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_APPLICATIONS,
      search: queryString.stringify({ jobId } ),
    });
  }, [redirectTo]);

  const goToApprovedApplications = useCallback(jobId => {
    redirectTo({
      pathname: ROUTES.EMPLOYER_ADMIN_APPLICATIONS,
      search: queryString.stringify({ jobId, candidateApprovalStatus: CandidateApprovalStatusEnum.APPROVED } ),
    });
  }, [redirectTo]);

  const columnsLabels = [
    t("admin:admin_job_createdAt_column_label"),
    t("admin:admin_job_title_column_label"),
    t("admin:admin_job_company_column_label"),
    t("admin:admin_job_publishing_status_label"),
    t("admin:admin_job_payment_status_column_label"),
    t("admin:admin_job_commission_column_label"),
    t("admin:admin_job_application_count_column_label"),
    t("admin:admin_job_approvedApplication_count_column_label")
  ];

  return (
    <>
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <Row className="widget-block">
            <Col>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    {columnsLabels.map((item, i) => (
                      <td key={i}>{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jobs["nodes"].map((item, i) => (
                    <tr key={i}>
                      <th>{i + 1}</th>
                      <td><DisplayDayIso date={item.createdAt} /></td>
                      <td><TruncateText title={t("admin:admin_job_title_column_label")} text={item.title} length={30}/></td>
                      <td><TruncateText title={t("admin:admin_job_company_column_label")} text={item.companyName} length={10}/></td>
                      <td>{item.status}</td>
                      <td>{item.paymentStatus}</td>
                      <td>{item.commission > 0 ? `$${item.commission}` : ""}</td>
                      <td><ActionLink onClick={() => goToAllApplications(item._id)}><ApplicationByJobCountItem jobId={item._id}/></ActionLink></td>
                      <td><ActionLink onClick={() => goToApprovedApplications(item._id)}><ApprovedApplicationByJobCountItem jobId={item._id}/></ActionLink></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      />
    </>
  );
}
