import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationChatGpt from "../useTranslationChatGpt.js";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import StringUtils from "../../../utils/StringUtils.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import StandardPrompt from "./StandardPrompt.js";
import PromptGenerator from "./PromptGenerator.js";

const getParamValue = (name, params) => {
  const param = TypeUtils.ensureArray(params).find(p => p.name === name);
  return (param && StringUtils.nullToEmpty(param.value)) || "";
}

const formatParam = (text) => {
  return "[" + text + "]";
}

export default function SavedPrompts({onSelect, organization, lang, profile}) {

  const { t, loading: tLoading } = useTranslationChatGpt();
  useShowLoader(tLoading);

  const JobPostingPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_job_posting_template", {job: getParamValue("job", params)}),
        (new PromptGenerator(lang, params, organization, profile)).jobPostingPrompt(),
        params)}
      template={t("chatgpt:standard_prompt_job_posting_template", {job: formatParam(t("chatgpt:standard_prompt_param_job"))})}
      params={[{name: "job", placeholder: t("chatgpt:standard_prompt_param_job")}]}
    />;

  const JobSkillsPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_job_skills_template", {job: getParamValue("job", params)}),
        (new PromptGenerator(lang, params, organization, profile)).jobSkillsPrompt(),
        params)}
      template={t("chatgpt:standard_prompt_job_skills_template", {job: formatParam(t("chatgpt:standard_prompt_param_job"))})}
      params={[{name: "job", placeholder: t("chatgpt:standard_prompt_param_job")}]}
    />;

  const ReferralPromotionalMaterialPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_referral_promotional_material_template"),
        (new PromptGenerator(lang, params, organization, profile)).referralPromotionalMaterial(),
        params)}
      template={t("chatgpt:standard_prompt_referral_promotional_material_template")}
      params={[{name: "reward", placeholder: t("chatgpt:standard_prompt_param_reward")}]}
    />;

  const ReferralStrategiesPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_referral_strategies_template"),
        (new PromptGenerator(lang, params, organization, profile)).referralStrategies(),
        params)}
      template={t("chatgpt:standard_prompt_referral_strategies_template")}
    />;

  const CandidateReceiptEmailPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_candidate_receipt_email_template"),
        (new PromptGenerator(lang, params, organization, profile)).candidateReceiptEmailPrompt(),
        params)}
      template={t("chatgpt:standard_prompt_candidate_receipt_email_template")}
      params={[
        {name: "job", placeholder: t("chatgpt:standard_prompt_param_job")},
        {name: "candidateName", placeholder: t("chatgpt:standard_prompt_param_candidate_name")}
      ]}
    />

  const CandidateRejectEmailPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_candidate_reject_email_template"),
        (new PromptGenerator(lang, params, organization, profile)).candidateRejectEmailPrompt(),
        params)}
      template={t("chatgpt:standard_prompt_candidate_reject_email_template")}
      params={[
        {name: "job", placeholder: t("chatgpt:standard_prompt_param_job")},
        {name: "candidateName", placeholder: t("chatgpt:standard_prompt_param_candidate_name")}
      ]}
    />;

  const CandidateHireLetterPrompt =
    <StandardPrompt
      onSelect={params => onSelect(
        t("chatgpt:standard_prompt_candidate_hire_letter_template"),
        (new PromptGenerator(lang, params, organization, profile)).candidateHireLetterPrompt(),
        params)}
      template={t("chatgpt:standard_prompt_candidate_hire_letter_template")}
      params={[
        {name: "job", placeholder: t("chatgpt:standard_prompt_param_job")},
        {name: "candidateName", placeholder: t("chatgpt:standard_prompt_param_candidate_name")}
      ]}
    />;

  return (
    <div className="StandardPrompts">
      <Row>
        <Col>
          {JobPostingPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {JobSkillsPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {ReferralPromotionalMaterialPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {ReferralStrategiesPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {CandidateReceiptEmailPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {CandidateHireLetterPrompt}
        </Col>
      </Row>
      <Row>
        <Col>
          {CandidateRejectEmailPrompt}
        </Col>
      </Row>
    </div>
  );
};
