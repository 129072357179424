import React from "react";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import StringUtils from "../../../utils/StringUtils.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./JobAverageCommissionTip.scss";

export default function JobAverageCommissionTip(props) {
  const { jobGlobalAverageCommission, similarJobs } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobAverageCommissionTip");

  // Remove invalid jobs
  const cleanedSimilarJobs = similarJobs.filter(
    (item) => item.employer && !StringUtils.isNullOrEmpty(item.employer.organization.company)
  );

  // If there are similar jobs, show the average commission of similar jobs, otherwise show global average commission
  const commissions = cleanedSimilarJobs.map((item) => item.commission);
  const jobAverageCommission =
    cleanedSimilarJobs.length > 0
      ? Math.ceil(
          commissions.reduce((a, b) => a + b, 0) / cleanedSimilarJobs.length
        )
      : jobGlobalAverageCommission;

  return (
    <>
      <Row className = "JobAverageCommissionTip">
        <Col>{t("jobs:employers_job_save_tip_average")}</Col>
        <Col className = "label">
          {t("jobs:employers_job_dollars", { amount: jobAverageCommission })}
        </Col>
      </Row>
    </>
  );
}
